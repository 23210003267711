import jsonServerProvider from "ra-data-json-server";
import { fetchUtils } from "react-admin";
import { uploadFile } from "../componets/SampleFiles/UploadFileButton";
import { uploadFilePredictClass } from "../Interfaces";
import { stringify } from "query-string";
import { ATS_API_URL, OLD_DOCUPLOAD_API } from "../config";
import { Type } from "react-toastify/dist/utils";
import ca from "date-fns/locale/ca";

const objectToQueryString = (obj) => {
  return Object.keys(obj)
    .map((key) => `${key.toLowerCase()}=${obj[key]}`)
    .join("&");
};
// const API_URL_DOC = 'https://uatapiml.dvapply.com/applicant';
const API_URL = ATS_API_URL;

const OLD_DOCUPLOAD_API_URL = OLD_DOCUPLOAD_API;
const API_URL_DOC = `${API_URL}/applicant`;

let apiCalled = false;
const httpClient = (url, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  // add your own headers here
  // {tokendata:token,trn_id}
  const { data } = JSON.parse(<any>localStorage.getItem("auth")) || {};
  options.headers.set("authorization", data?.tokendata || null);
  options.headers.set("trn_id", data?.trn_id || null);
  return fetchUtils
    .fetchJson(url, options)
    .then((res) => {
      return res;
    })
    .catch((error: any) => {
      return new Promise(async function (resolve, reject) {
        //  debugger
        if (error.status == 401 && !apiCalled) {
          try {
            apiCalled = true;
            const localStorageData =
              JSON.parse(<any>localStorage.getItem("auth")) || {};
            const requestOptions = {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                refreshtoken: localStorageData?.data?.refreshtoken,
              }),
            };
            const response: any = await fetch(
              `${API_URL}/auth/refreshtoken`,
              requestOptions
            );
            if (!response.ok) {
              localStorage.removeItem("auth");
              window.location.replace(
                window.location.protocol +
                "//" +
                window.location.host +
                window.location.href.split(
                  window.location.protocol + "//" + window.location.host
                )[1]
              );
            } else {
              const res = await response.json();
              localStorage.removeItem("auth");
              localStorage.setItem(
                "auth",
                JSON.stringify({
                  ...localStorageData,
                  data: {
                    ...localStorageData?.data,
                    tokendata: res?.data?.tokendata,
                    refreshtoken: res?.data?.refreshtoken,
                  },
                })
              );
              apiCalled = false;
              const { data } =
                JSON.parse(<any>localStorage.getItem("auth")) || {};
              options.headers.set("authorization", data?.tokendata || null);
              return resolve(fetchUtils.fetchJson(url, options));
            }
          } catch (e: any) {
            localStorage.removeItem("auth");
            window.location.replace(
              window.location.protocol +
              "//" +
              window.location.host +
              window.location.href.split(
                window.location.protocol + "//" + window.location.host
              )[1]
            );
          }
          //   localStorage.removeItem('auth');
          // sessionStorage.setItem('locationRequestToRedirect', window.location.href)
          //   window.location.replace(window.location.protocol + '//' + window.location.host + window.location.href.split(window.location.protocol + '//' + window.location.host)[1])
        }
        reject({ message: error?.body?.message });
      });
    });
};

const baseDataProvider = jsonServerProvider(API_URL, <any>httpClient);

export const dataProvidersAts = {
  ...baseDataProvider,
  create: async (resource, params) => {
    if (resource === "updatereviewdone") {
      console.log(params, "params");
      try {
        const raw = JSON.stringify({ ...params.data });
        const { json, headers, status }: any = await httpClient(
          API_URL + `/common/updatereviewdone`,
          {
            method: "POST",
            body: raw,
          }
        );
        const r = json;
        const res = {
          data: {
            id: params?.data?.applicationnumber,
            ...r?.records,
          },
        };
        return res;
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource == "addupdatetermsandcondition") {
      try {
        const raw = JSON.stringify({ ...params.data });
        const { json, headers, status }: any = await httpClient(
          API_URL + `/subscriber/addupdatetermsandcondition`,
          {
            method: "POST",
            body: raw,
          }
        );
        const r = json;
        const res = {
          data: {
            id: 1,
            ...r?.records,
          },
        };
        return res;
      } catch (e: any) {
        throw new Error(e.message);
      }
    }

    if (resource == "addupdatetermsandcondition_admin") {
      try {
        const raw = JSON.stringify({ ...params.data });
        const { json, headers, status }: any = await httpClient(
          API_URL + '/admin/updatetermsandcondition',
          {
            method: "POST",
            body: raw,
          }
        );
        const r = json;
        const res = {
          data: {
            id: 1,
            ...r?.records,
          },
        };
        return res;
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource === "reprocessEmployer") {
      console.log(params, "params");
      try {
        const raw = JSON.stringify({ ...params.data });
        const { json, headers, status }: any = await httpClient(
          API_URL_DOC + `/income/reprocessEmployer`,
          {
            method: "POST",
            body: raw,
          }
        );
        const r = json;
        const res = {
          data: {
            id: params?.id,
            ...r?.records,
          },
        };
        return res;
      } catch (e: any) {
        throw new Error(e.message);
      }
    }

    if (resource === "automovedocument") {
      console.log(params, "params");
      try {
        const raw = JSON.stringify({ ...params.data });
        const { json, headers, status }: any = await httpClient(
          API_URL + `/admin/automovedocument?application_document_id=${params?.data?.application_document_id}`,
          {
            method: "POST",
            body: raw,
          }
        );
        const r = json;
        const res = {
          data: {
            id: params?.data?.application_document_id,
            ...r?.records,
          },
        };
        return res;
      } catch (e: any) {
        throw new Error(e.message);
      }
    }

    if (resource === "getscreeningreport") {
      const { data } = JSON.parse(<any>localStorage.getItem("auth")) || {};

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("authorization", data?.tokendata || null);

      const raw = JSON.stringify(params.data);

      const requestOptions: any = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      try {
        const response = await fetch(
          `${API_URL}/subscriber/getscreeningreport`,
          requestOptions
        );

        if (response.status != 200) {
          const result = await response.json();
          throw new Error(result?.message);
        }
        const result = await response.blob();
        const url = window.URL.createObjectURL(result);

        const a = document.createElement("a");
        a.href = url;
        a.target = "_blank";
        a.click();

        return {
          data: { id: 1 },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    }

    if (resource === "loginFromAdminDashboard") {
      try {
        const raw = JSON.stringify({ ...params.data });
        const { json }: any = await httpClient(
          `${API_URL_DOC}/users/LoginFromAdminDashboard`,
          {
            method: "POST",
            body: raw,
          }
        );
        // const r = await result.json();
        const res = {
          data: {
            id: 1,
            ...json,
          },
        };
        return res;
      } catch (e) {
        throw new Error("something went wrong, try again!");
      }
    }

    if (resource === "loginFromAdminDashboardInvitedApp") {
      try {
        const { json, headers, status }: any = await httpClient(`${API_URL}/common/openSentInviteApp`, {
          method: 'POST',
          body: JSON.stringify(params.data),
        });
        return {
          data: { id: 1, ...json }
        };
      } catch (e) {
        throw new Error("Applicant is inactive or not found!");
      }
    }

    if (resource === "generateOTP_docupload") {
      try {
        const raw = JSON.stringify({ ...params.data });
        const { json }: any = await httpClient(
          `${OLD_DOCUPLOAD_API_URL}/upload/GenerateToken`,
          {
            method: "POST",
            body: raw,
          }
        );
        // const r = await result.json();
        const res = {
          data: {
            id: 1,
            ...json,
          },
        };
        return res;
      } catch (e) {
        throw new Error("something went wrong, try again!");
      }
    }
    if (resource === "mlclassdetails") {
      try {
        const raw = JSON.stringify({ ...params.data });
        const { json }: any = await httpClient(
          `${API_URL_DOC}/ml/mlclassdetails`,
          {
            method: "POST",
            body: raw,
          }
        );
        // const r = await result.json();
        const res = {
          data: {
            ...json,
          },
        };
        return res;
      } catch (e) {
        throw new Error("something went wrong, try again!");
      }
    }
    if (resource === "generateOtp") {
      try {
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ ...params.data });
        const result: any = await fetch(API_URL_DOC + "/auth/GenerateOtp", {
          method: "POST",
          body: raw,
          headers: myHeaders,
        });

        const r = await result.json();
        const res = {
          data: {
            id: params.id,
            ...r?.records,
          },
        };
        return res;
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource === "logout") {
      try {
        const { json }: any = await httpClient(API_URL + `/account/logout`, {
          method: "POST",
        });
        const r = json;
        const res = {
          data: {
            id: params.id,
            ...r?.records,
          },
        };
        return res;
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource === "loginTokenCheckDocupload") {
      try {
        const { data } = JSON.parse(<any>localStorage.getItem("auth")) || {};
        const raw = JSON.stringify({ ...params.data, token: data?.tokendata });
        const { json, headers, status }: any = await httpClient(
          API_URL_DOC + `/auth/superuser`,
          {
            method: "POST",
            body: raw,
          }
        );
        const r = json;
        const res = {
          data: {
            id: params.id,
            ...r?.records,
          },
        };
        return res;
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource == "fileUpload") {
      try {
        const formdata = new FormData();
        formdata.append("trn_id", params?.data?.trn_id);
        for (let i = 0; i < params?.data?.files.length; i++) {
          formdata.append("files[]", params?.data?.files[i]);
        }
        formdata.append("document_id", params?.data?.document_id);
        formdata.append("source_id", params?.data?.source_id);
        formdata.append("allowOfferLetter", params?.data?.allowOfferLetter);
        const { json, headers, status }: any = await httpClient(
          API_URL_DOC + `/applicant/document`,
          {
            method: "POST",
            body: formdata,
          }
        );
        return { data: { ...params, ...json, id: params?.data?.trn_id } };
      } catch (error: any) {
        throw new Error(error.message);
      }
    }
    if (resource === "income") {
      try {
        const raw = JSON.stringify({ ...params.data });
        const { json, headers, status }: any = await httpClient(
          API_URL_DOC + `/income/addSource`,
          {
            method: "POST",
            body: raw,
          }
        );
        const r = json;
        const res = {
          data: {
            id: params.id,
            ...r?.records,
          },
        };
        return res;
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource === "updateApplicationStatus") {
      try {
        const raw = JSON.stringify({ ...params.data });
        const { json, headers, status }: any = await httpClient(
          API_URL_DOC + `/document/updateApplicationStatus`,
          {
            method: "POST",
            body: raw,
          }
        );
        const r = json;
        const res = {
          data: {
            id: params.data.trn_id,
            ...r,
          },
        };
        return res;
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource == "addcoappcosigner") {
      try {
        const { json }: any = await httpClient(
          `${API_URL}/common/addcoappcosigner`,
          {
            method: "POST",
            body: JSON.stringify(params.data),
          }
        );
        const res = json;
        return {
          data: { ...res.data },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    } else if (resource == "sendemailtoapplicant") {
      try {
        const { json }: any = await httpClient(
          `${API_URL}/subscriber/sendemailtoapplicant`,
          {
            method: "POST",
            body: JSON.stringify(params.data),
          }
        );
        const res = json;
        return {
          data: { ...res.data },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    } else if (resource == "sendsmstoapplicant") {
      try {
        const { json }: any = await httpClient(
          `${API_URL}/subscriber/sendsmstoapplicant`,
          {
            method: "POST",
            body: JSON.stringify(params.data),
          }
        );
        const res = json;
        return {
          data: { ...res.data },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    } else if (resource == "sendsecondaryapp") {
      try {
        const { json }: any = await httpClient(
          `${API_URL}/subscriber/sendsecondaryapp`,
          {
            method: "POST",
            body: JSON.stringify(params.data),
          }
        );
        const res = json;
        return {
          data: { ...res.data },
        };
      } catch (error) {
        throw new Error("something went wrong, try again!");
      }
    } else if (resource == "addnewapplication") {
      try {
        const { json, headers, status }: any = await httpClient(
          `${API_URL}/common/addnewapplication`,
          {
            method: "POST",
            body: JSON.stringify(params.data),
          }
        );
        return {
          data: { id: 1, ...json },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource == "saveVerificationStatus") {
      try {
        const { json, headers, status }: any = await httpClient(
          API_URL_DOC + "/income/saveVerificationStatus",
          {
            method: "POST",
            body: JSON.stringify(params.data),
          }
        );

        return {
          data: { id: 1, ...json.data },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    }

    if (resource == "saveSOCRComment") {
      try {
        const raw = JSON.stringify(params.data);
        const { json, headers, status }: any = await httpClient(
          API_URL_DOC + `/document/saveSOCRComment`,
          {
            method: "POST",
            body: raw,
          }
        );
        return {
          data: { id: 1, ...json.data },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource == "addNotes") {
      try {
        const raw = JSON.stringify(params.data);

        const { json, headers, status }: any = await httpClient(
          API_URL + `/subscriber/addNotes`,
          {
            method: "POST",
            body: raw,
          }
        );
        return {
          data: { id: 1, ...json.data },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    }

    if (resource == "approvedWithConditions") {
      // try {
      //   const raw = JSON.stringify(params.data);

      //   console.log(raw,"rawdata");
      //   const { json, headers, status, body}: any = await httpClient(
      //     API_URL + `/subscriber/addupdatelandlordadversedecision?subscriberid=${params.data.subscriberid}`,
      //     {
      //       method: "POST",
      //       body: raw,
      //       // headers: new Headers({
      //       //   'Content-Type':'application/pdf'
      //       // }),

      //     }
      //   );
      //   debugger;
      //   return {
      //     data: { id: 1, ...json?.data,blobdata:body.blob()},
      //   };
      // } catch (e: any) {
      //   throw new Error(e.message);
      // }
      // try {
      //   const { data } = JSON.parse(<any>localStorage.getItem("auth")) || {};
      //   const raw = JSON.stringify(params.data);
      //   const headers = new Headers();
      //   // headers.append("authorization", data?.tokendata || null);
      //   // headers.append("trn_id", data?.trn_id || null);

      //   // Make the fetch request with the headers
      //   const response = await fetch(
      //     API_URL +
      //       `/subscriber/addupdatelandlordadversedecision?subscriberid=${params.data.subscriberid}`,
      //     {
      //       method: "POST",
      //       // headers: headers,
      //       body: raw,
      //       // redirect:"follow",
      //       // mode:"cors",
      //     }
      //   );
      //   debugger;
      //   if (!response.ok) {
      //     throw new Error("Failed to fetch ZIP file");
      //   }

      //   // Convert the response body to a Blob
      //   const blob = await response.blob();

      //   // Create a Blob URL for the ZIP file
      //   const url = window.URL.createObjectURL(blob);

      //   // Create a temporary link element and trigger the download
      //   const a = document.createElement("a");
      //   a.href = url;
      //   a.download = "docs.pdf"; // Set the desired file name
      //   a.click();

      //   // Clean up by revoking the URL object
      //   window.URL.revokeObjectURL(url);
      //   // const { body }: any = await httpClient(API_URL + '/common/getdownloadalldocs/' + payload);
      //   return { data: response };
      // } catch (error: any) {
      //   throw new Error(error.message);
      // }

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify(params.data);

      const requestOptions: any = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      try {
        const response = await fetch(
          API_URL +
          `/subscriber/addupdatelandlordadversedecision?subscriberid=${params.data.subscriberid}`,
          requestOptions
        );

        let result;
        if (params.data.IsEmail) {
          result = await response.json();
        } else {
          result = await response.blob();
          const url = window.URL.createObjectURL(result);

          const a = document.createElement("a");
          a.href = url;
          if (params.data.IsPrint) a.target = "_blank";
          else a.download = "docs.pdf"; // Set the desired file name
          a.click();

          // Clean up by revoking the URL object
          window.URL.revokeObjectURL(url);
        }

        console.log(result);
        return {
          data: {
            ...params.data,
            id: 1,
          },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource == "savedetails") {
      try {
        const raw = JSON.stringify(params.data);
        const { json, headers, status }: any = await httpClient(
          API_URL_DOC + `/contextual/savedetails`,
          {
            method: "POST",
            body: raw,
          }
        );
        return {
          data: { id: 1, ...json.data },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    } else if (resource == "resendinvite") {
      try {
        const { json, headers, status }: any = await httpClient(
          `${API_URL}/common/resendinvite`,
          {
            method: "POST",
            body: JSON.stringify(params.data),
          }
        );
        return {
          data: { id: 1, ...json },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    } else if (resource == "shareappreport") {
      try {
        const { json, headers, status }: any = await httpClient(
          `${API_URL}/common/shareappreport`,
          {
            method: "POST",
            body: JSON.stringify(params.data),
          }
        );
        return {
          data: { id: 1, ...json },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    } else if (resource == "resendshareappreport") {
      try {
        const { json, headers, status }: any = await httpClient(
          `${API_URL}/common/resendshareappreport`,
          {
            method: "POST",
            body: JSON.stringify(params.data),
          }
        );
        return {
          data: { id: 1, ...json },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    } else if (resource == "accountLogin") {
      try {
        const { json, headers, status }: any = await httpClient(
          `${API_URL}/account/Login`,
          {
            method: "POST",
            body: JSON.stringify(params.data),
            // headers: {"Content-Type": "application/json"},
          }
        );
        return {
          data: { id: 1, ...json },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    } else if (resource == "updatenewtagsection") {
      try {
        const { json, headers, status }: any = await httpClient(
          `${API_URL}/common/updatenewtagsection`,
          {
            method: "POST",
            body: JSON.stringify(params.data),
          }
        );
        return {
          data: { id: 1, ...json },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    } else if (resource == "updatenewtagapp") {
      try {
        const { json, headers, status }: any = await httpClient(
          `${API_URL}/common/updatenewtagapp`,
          {
            method: "POST",
            body: JSON.stringify(params.data),
          }
        );
        return {
          data: { id: 1, ...json },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    } else if (resource == "forgotPassword") {
      try {
        const { json, headers, status }: any = await httpClient(
          `${API_URL}/account/forgotPassword`,
          {
            method: "POST",
            body: JSON.stringify(params.data),
          }
        );
        return {
          data: { id: 1, ...json },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    } else if (resource == "mfa_VerifyOtp") {
      try {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify(params.data);

        const requestOptions: any = {
          method: "POST",
          headers: myHeaders,
          credentials: "include",
          body: raw,
        };

        // fetch(API_URL + "/api/account/VerifyOtp", requestOptions)
        //   .then((response) => response.text())
        //   .then((result) => console.log(result))
        //   .catch((error) => console.error(error));

        const response = await fetch(
          API_URL + "/account/VerifyOtp",
          requestOptions
        );
        // await response.text();
        const result = await response.json();

        if (response.status != 200) {
          throw new Error(result.message);
        }
        // const { json, headers, status }: any = await httpClient(`${API_URL}/account/VerifyOtp`, {
        //   method: 'POST',
        //   body: JSON.stringify(params.data),
        // });
        return {
          data: {
            id: 1,
            ...result,
          },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    } else if (resource == "mfa_sendmfaallotp") {
      try {
        const { json, headers, status }: any = await httpClient(
          `${API_URL}/account/sendmfaallotp`,
          {
            method: "POST",
            body: JSON.stringify(params.data),
          }
        );
        return {
          data: { id: 1, ...json },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    } else if (resource == "mfa_SendMfaEmailOtp") {
      try {
        const { json, headers, status }: any = await httpClient(
          `${API_URL}/account/SendMfaEmailOtp`,
          {
            method: "POST",
            body: JSON.stringify(params.data),
          }
        );
        return {
          data: { id: 1, ...json },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    } else if (resource == "mfa_sendmfasmsotp") {
      try {
        const { json, headers, status }: any = await httpClient(
          `${API_URL}/account/sendmfasmsotp`,
          {
            method: "POST",
            body: JSON.stringify(params.data),
          }
        );
        return {
          data: { id: 1, ...json },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    } else if (resource == "resetPassword") {
      try {
        const { json, headers, status }: any = await httpClient(
          `${API_URL}/account/resetPassword`,
          {
            method: "POST",
            body: JSON.stringify(params.data),
          }
        );
        return {
          data: { id: 1, ...json },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    } else if (resource == "processScreening") {
      try {
        const { json, headers, status }: any = await httpClient(
          `${API_URL}/subscriber/process-screening`,
          {
            method: "POST",
            body: params?.data,
          }
        );
        return {
          data: { id: 1, ...json },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    } else if (resource == "add-property") {
      try {
        const { json, headers, status }: any = await httpClient(
          `${API_URL}/subscriber/add-property`,
          {
            method: "POST",
            body: params?.data,
          }
        );
        return {
          data: { id: 1, ...json },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    } else if (resource == "update-property") {
      try {
        const { json, headers, status }: any = await httpClient(
          `${API_URL}/subscriber/update-property`,
          {
            method: "PUT",
            body: params?.data,
          }
        );
        return {
          data: { id: 1, ...json },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    } else if (resource == "add-floor") {
      try {
        const { json, headers, status }: any = await httpClient(
          `${API_URL}/subscriber/add-floor`,
          {
            method: "POST",
            body: JSON.stringify(params?.data),
          }
        );
        return {
          data: { id: 1, ...json },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    } else if (resource == "update-floor") {
      try {
        const { json, headers, status }: any = await httpClient(
          `${API_URL}/subscriber/update-floor`,
          {
            method: "PUT",
            body: JSON.stringify(params?.data),
          }
        );
        return {
          data: { id: 1, ...json },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    } else if (resource == "add-unit") {
      try {
        const { json, headers, status }: any = await httpClient(
          `${API_URL}/subscriber/add-unit`,
          {
            method: "POST",
            body: JSON.stringify(params?.data),
          }
        );
        return {
          data: { id: 1, ...json },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource == "saveiddetail") {
      try {
        const raw = JSON.stringify(params.data);
        const { json, headers, status }: any = await httpClient(
          API_URL_DOC + `/applicant/saveiddetail`,
          {
            method: "POST",
            body: raw,
          }
        );
        return {
          data: { id: 1, ...json.data },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource == "savePayment") {
      try {
        const raw = JSON.stringify(params.data);
        const { json, headers, status }: any = await httpClient(
          API_URL_DOC + `/stripe/payment`,
          {
            method: "POST",
            body: raw,
          }
        );
        return {
          data: { id: 1, ...json.data },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource == "savedetails") {
      try {
        const raw = JSON.stringify(params.data);
        const { json, headers, status }: any = await httpClient(
          API_URL_DOC + `/contextual/savedetails`,
          {
            method: "POST",
            body: raw,
          }
        );
        return {
          data: { id: 1, ...json.data },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    } else if (resource == "resendinvite") {
      try {
        const { json, headers, status }: any = await httpClient(
          `${API_URL}/common/resendinvite`,
          {
            method: "POST",
            body: JSON.stringify(params.data),
          }
        );
        return {
          data: { id: 1, ...json },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    } else if (resource == "shareappreport") {
      try {
        const { json, headers, status }: any = await httpClient(
          `${API_URL}/common/shareappreport`,
          {
            method: "POST",
            body: JSON.stringify(params.data),
          }
        );
        return {
          data: { id: 1, ...json },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    } else if (resource == "resendshareappreport") {
      try {
        const { json, headers, status }: any = await httpClient(
          `${API_URL}/common/resendshareappreport`,
          {
            method: "POST",
            body: JSON.stringify(params.data),
          }
        );
        return {
          data: { id: 1, ...json },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    } else if (resource == "accountLogin") {
      try {
        const { json, headers, status }: any = await httpClient(
          `${API_URL}/account/Login`,
          {
            method: "POST",
            body: JSON.stringify(params.data),
            // headers: {"Content-Type": "application/json"},
          }
        );
        return {
          data: { id: 1, ...json },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    } else if (resource == "updatenewtagsection") {
      try {
        const { json, headers, status }: any = await httpClient(
          `${API_URL}/common/updatenewtagsection`,
          {
            method: "POST",
            body: JSON.stringify(params.data),
          }
        );
        return {
          data: { id: 1, ...json },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    } else if (resource == "updatenewtagapp") {
      try {
        const { json, headers, status }: any = await httpClient(
          `${API_URL}/common/updatenewtagapp`,
          {
            method: "POST",
            body: JSON.stringify(params.data),
          }
        );
        return {
          data: { id: 1, ...json },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    } else if (resource == "forgotPassword") {
      try {
        const { json, headers, status }: any = await httpClient(
          `${API_URL}/account/forgotPassword`,
          {
            method: "POST",
            body: JSON.stringify(params.data),
          }
        );
        return {
          data: { id: 1, ...json },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    } else if (resource == "resetPassword") {
      try {
        const { json, headers, status }: any = await httpClient(
          `${API_URL}/account/resetPassword`,
          {
            method: "POST",
            body: JSON.stringify(params.data),
          }
        );
        return {
          data: { id: 1, ...json },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    } else if (resource == "processScreening") {
      try {
        const { json, headers, status }: any = await httpClient(
          `${API_URL}/subscriber/process-screening`,
          {
            method: "POST",
            body: params?.data,
          }
        );
        return {
          data: { id: 1, ...json },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    } else if (resource == "add-property") {
      try {
        const { json, headers, status }: any = await httpClient(
          `${API_URL}/subscriber/add-property`,
          {
            method: "POST",
            body: params?.data,
          }
        );
        return {
          data: { id: 1, ...json },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    } else if (resource == "update-property") {
      try {
        const { json, headers, status }: any = await httpClient(
          `${API_URL}/subscriber/update-property`,
          {
            method: "PUT",
            body: params?.data,
          }
        );
        return {
          data: { id: 1, ...json },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    } else if (resource == "add-floor") {
      try {
        const { json, headers, status }: any = await httpClient(
          `${API_URL}/subscriber/add-floor`,
          {
            method: "POST",
            body: JSON.stringify(params?.data),
          }
        );
        return {
          data: { id: 1, ...json },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    } else if (resource == "update-floor") {
      try {
        const { json, headers, status }: any = await httpClient(
          `${API_URL}/subscriber/update-floor`,
          {
            method: "PUT",
            body: JSON.stringify(params?.data),
          }
        );
        return {
          data: { id: 1, ...json },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    } else if (resource == "add-unit") {
      try {
        const { json, headers, status }: any = await httpClient(
          `${API_URL}/subscriber/add-unit`,
          {
            method: "POST",
            body: JSON.stringify(params?.data),
          }
        );
        return {
          data: { id: 1, ...json },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    } else if (resource == "update-unit") {
      try {
        const { json, headers, status }: any = await httpClient(
          `${API_URL}/subscriber/update-unit`,
          {
            method: "PUT",
            body: JSON.stringify(params?.data),
          }
        );
        return {
          data: { id: 1, ...json },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    } else if (resource == "add-integration-setting-rule") {
      try {
        const { json, headers, status }: any = await httpClient(
          `${API_URL}/subscriber/integration-setting-rule`,
          {
            method: "POST",
            body: JSON.stringify(params?.data),
          }
        );
        return {
          data: { id: 1, ...json },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    } else if (resource == "update-integration-setting-rule") {
      try {
        const { json, headers, status }: any = await httpClient(
          `${API_URL}/subscriber/integration-setting-rule`,
          {
            method: "PUT",
            body: JSON.stringify(params?.data),
          }
        );
        return {
          data: { id: 1, ...json },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    } else if (resource == "add-subscriber") {
      try {
        const { json, headers, status }: any = await httpClient(
          `${API_URL}/subscriber/add-subscriber`,
          {
            method: "POST",
            body: params?.data,
          }
        );
        return {
          data: { id: 1, ...json },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    } else if (resource == "update-subscriber") {
      try {
        const { json, headers, status }: any = await httpClient(
          `${API_URL}/subscriber/update-subscriber`,
          {
            method: "PUT",
            body: params?.data,
          }
        );
        return {
          data: { id: 1, ...json },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    } else if (resource == "subscriber-status") {
      try {
        const { json, headers, status }: any = await httpClient(
          `${API_URL}/subscriber/subscriber-status?subscriberID=${params?.data?.subscriberID}&status=${params?.data?.status}`,
          {
            method: "PUT",
            // body: JSON.stringify(params?.data),
          }
        );
        return {
          data: { id: 1, ...json },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    } else if (resource == "add-hierarchy-levels") {
      try {
        const { json, headers, status }: any = await httpClient(
          `${API_URL}/subscriber/hierarchy-levels`,
          {
            method: "POST",
            body: JSON.stringify(params?.data),
          }
        );
        return {
          data: { id: 1, ...json },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    } else if (resource == "update-hierarchy-levels") {
      try {
        const { json, headers, status }: any = await httpClient(
          `${API_URL}/subscriber/hierarchy-levels`,
          {
            method: "PUT",
            body: JSON.stringify(params?.data),
          }
        );
        return {
          data: { id: 1, ...json },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    } else if (resource == "updateStripeAccount") {
      try {
        const { json, headers, status }: any = await httpClient(
          `${API_URL}/subscriber/addStripe`,
          {
            method: "PUT",
            body: JSON.stringify(params?.data),
          }
        );
        return {
          data: { id: 1, ...json },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    } else if (resource == "add-office") {
      try {
        const { json, headers, status }: any = await httpClient(
          `${API_URL}/subscriber/add-office`,
          {
            method: "POST",
            body: params?.data,
          }
        );
        return {
          data: { id: 1, ...json },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    } else if (resource == "update-office") {
      try {
        const { json, headers, status }: any = await httpClient(
          `${API_URL}/subscriber/update-office`,
          {
            method: "PUT",
            body: params?.data,
          }
        );
        return {
          data: { id: 1, ...json },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    } else if (resource == "import-xls") {
      try {
        const { json, headers, status }: any = await httpClient(
          `${API_URL}/subscriber/import-xls`,
          {
            method: "POST",
            body: params?.data,
          }
        );
        return {
          data: { id: 1, ...json },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    } else if (resource == "sendLeaseDocument") {
      try {
        const { json, headers, status }: any = await httpClient(
          `${API_URL}/applicant/document/sendLeaseDocument?trn_id=${params?.data?.trn_id}&url=${params?.data?.url}`,
          {
            method: "POST",
          }
        );
        return {
          data: { id: 1, ...json },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    return baseDataProvider.create(resource, params);
  },
  getOne: async (resource, params) => {
    ///move documents
    if (resource === "getsectionavailable") {
      try {
        const { json, headers, status }: any = await httpClient(
          API_URL + `/admin/getsectionavailable?applicantID=${params?.id}`
        );
        const r = json;
        const res = {
          data: { ...r?.data },
        };
        return res;
      } catch (e: any) {
        throw new Error(e.message);
      }
    }

    if (resource === "getTokkenDetails") {
      try {
        // const raw=JSON.stringify({token:params.id});
        // const {json}:any = await httpClient(API_URL + `/auth/gettokendetails`, {
        //   method: "GET",
        //   body:raw,
        // });
        // const r = json;

        const { json, headers, status }: any = await httpClient(
          API_URL + `/auth/gettokendetails?token=${params.id}`
        );
        const r = json;
        const res = {
          data: { ...r?.data, id: params.id },
        };
        return res;
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource === "getApplicantDetails") {
      try {
        const { json, headers, status }: any = await httpClient(
          API_URL_DOC + `/auth/GetApplicantDetails/${params.id}`
        );
        const r = json;
        const res = {
          data: { ...r?.records, id: params.id },
        };

        return res;
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource === "mlclassdetails") {
      try {
        const { json }: any = await httpClient(
          API_URL_DOC + `/ml/getTemplateById/${params.id}`,
          {
            method: "GET",
          }
        );
        const r = json;
        const res = {
          data: {
            ...r?.records,
          },
        };
        return res;
      } catch (e: any) {
        throw new Error(e.message);
      }
    }

    if (resource === "getSubscriberProfileSection_new") {
      try {
        const { json, headers, status }: any = await httpClient(
          API_URL_DOC + `/document/getSubscriberProfileSection_new/${params.id}`
        );
        const r = json;
        const res = {
          data: {
            id: params.id,
            status: r?.records,
          },
        };
        return res;
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource === "proofOfIdsData") {
      try {
        const { json, headers, status }: any = await httpClient(
          API_URL_DOC + `/applicant/getidentification/${params.id}`
        );
        const r = json;
        let otherNoDocReason: string | null = null;
        let noDocReason: string | null = null;
        if (r?.records.identification_data.file) {
          otherNoDocReason = null;
          noDocReason = null;
        } else if (!r?.records.identification_data.no_doc_reason) {
          otherNoDocReason = null;
          noDocReason =
            "I do not currently have access to document(s), but can provide later";
        } else if (
          r?.records.identification_data.no_doc_reason !=
          "I do not currently have access to document(s), but can provide later" &&
          r?.records.identification_data.no_doc_reason !=
          "I do not have proof of identification"
        ) {
          otherNoDocReason = r?.records.identification_data.no_doc_reason;
          noDocReason = "Other";
        } else {
          otherNoDocReason = null;
          noDocReason = r?.records.identification_data.no_doc_reason;
        }
        const res = {
          data: {
            id: params.id,
            ...r?.records,
            identification_data: {
              ...r?.records.identification_data,
              // no_doc_reason: noDocReason,
              // other_reason: otherNoDocReason
            },
          },
        };
        return res;
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource === "income") {
      try {
        const { json, headers, status }: any = await httpClient(
          API_URL_DOC + `/income/getIncome/${params.id}`
        );
        const r = json;
        // finding a state default value
        const statedefaultValue = (employer_state: string) => {
          const findStatedefaultValue = r?.records?.States?.find(
            (item) => item?.StateShortCode == employer_state
          );
          if (findStatedefaultValue) {
            return {
              label: findStatedefaultValue
                ? findStatedefaultValue?.StateShortCode +
                " - " +
                findStatedefaultValue?.StateName
                : null,
              value: findStatedefaultValue ? findStatedefaultValue?.ID : null,
            };
          } else {
            return null;
          }
        };
        const newRes1 = {
          ...r,
          records: {
            ...r?.records,
            employment_details: {
              ...r?.records.employment_details,
              employer: r?.records.employment_details.employer.map((item) => {
                return {
                  ...item,
                  files: item.files.map((file) => {
                    return {
                      ...file,
                      Pay_date: file?.Pay_date?.split("T").shift(),
                      End_date: file?.End_date?.split("T").shift(),
                      Start_date: file?.Start_date?.split("T").shift(),
                      Pay_frequency: {
                        value: file.Pay_frequency,
                        label: file.Pay_frequency,
                      },
                    };
                  }),
                  employer_status: item?.employer_status
                    ? {
                      value: item?.employer_status,
                      label: item?.employer_status,
                    }
                    : null,
                  pay_frequency: item?.pay_frequency
                    ? { value: item?.pay_frequency, label: item?.pay_frequency }
                    : null,
                  employer_state: statedefaultValue(item?.employer_state),
                  availableToUpload:
                    item.files.length > 0
                      ? "Yes"
                      : item.no_doc_reason == null
                        ? ""
                        : "No",
                  fileCount: item.files.length,
                };
              }),
              employerCount: r.records.employment_details.employer.length,
            },
          },
        };
        const res = {
          data: {
            id: params.id,
            ...newRes1.records,
          },
        };
        return res;
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource === "bankDoc") {
      try {
        const { json, headers, status }: any = await httpClient(
          API_URL_DOC + `/income/getbank/${params.id}`
        );
        const r = json;
        const newRes1 = {
          ...r,
          records: {
            ...r?.records,
            banking_details: {
              ...r?.records.banking_details,
              banking: r?.records.banking_details.banking.map((item) => {
                return {
                  ...item,
                  availableToUpload:
                    item.files.length > 0
                      ? "Yes"
                      : item.no_doc_reason != null
                        ? "No"
                        : "",
                  no_doc_reason:
                    item.files.length > 0
                      ? null
                      : item.no_doc_reason ||
                      "I don't currently have access to document(s), but can provide later",
                  fileCount: item.files.length,
                };
              }),
              // employerCount: r.records.employment_details.employer.length,
            },
          },
        };
        const res = {
          data: {
            id: params.id,
            ...newRes1.records,
          },
        };
        // const res = {
        //   data: {
        //     id: params.id,
        //     ...r?.records,
        //   },
        // };
        return res;
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource === "otherIncomeDoc") {
      try {
        const { json, headers, status }: any = await httpClient(
          API_URL_DOC + `/income/getotherincome/${params.id}`
        );
        const r = json;
        const newRes1 = {
          ...r,
          records: {
            ...r?.records,
            other_income_details: {
              ...r?.records.other_income_details,
              otherincome: r?.records.other_income_details.otherincome.map(
                (item) => {
                  return {
                    ...item,
                    availableToUpload:
                      item.files.length > 0
                        ? "Yes"
                        : item.no_doc_reason != null
                          ? "No"
                          : "",
                    // no_doc_reason: item.no_doc_reason || "I don't currently have access to document(s), but can provide later",
                    fileCount: item.files.length,
                  };
                }
              ),
              // employerCount: r.records.employment_details.employer.length,
            },
          },
        };
        const res = {
          data: {
            id: params.id,
            ...newRes1.records,
          },
        };
        return res;
        // const res = {
        //   data: {
        //     id: params.id,
        //     ...r?.records,
        //   },
        // };
        // return res;
      } catch (e: any) {
        throw new Error(e.message);
      }
    }

    if (resource === "otherDoc") {
      try {
        const { json, headers, status }: any = await httpClient(
          API_URL_DOC + `/income/getothedocs/${params.id}`
        );
        const r = json;
        const res = {
          data: {
            id: params.id,
            ...r?.records,
          },
        };
        return res;
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource === "getMetaData") {
      try {
        const { json, headers, status }: any = await httpClient(
          API_URL_DOC +
          `/income/docupload/getMetadata/${params.meta.trn_id}/${params.id}`
        );
        const res = {
          data: {
            id: params.id,
            ...json,
          },
        };

        return res;
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource === "getapplicationgraph") {
      try {
        let url = `/common/getapplicationgraph?subscriberid=${params.id
          }&app_invitation_type=${params?.meta?.app_invitation_type || 1}`;
        if (!params.id)
          url = `/common/getapplicationgraph?app_invitation_type=${params?.meta?.app_invitation_type || 1
            }`;
        const { json }: any = await httpClient(`${API_URL}${url}`, {
          method: "GET",
        });
        const res = await json;
        return {
          data: { id: 1, ...res.data },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource === "getapplicationcountstatus") {
      try {
        const { json }: any = await httpClient(
          `${API_URL}/common/getapplicationcountstatus?app_invitation_type=${params?.meta?.app_invitation_type}` +
          (params.id ? `&subscriberid=${params.id}` : ""),
          { method: "GET" }
        );
        const res = await json;
        return {
          data: { id: 1, records: res.data },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource === "getfilterforinbox") {
      try {
        const { json, headers, status }: any = await httpClient(
          `${API_URL}/common/getfilterforinbox` +
          (params.id ? `?subscriberid=${params.id}` : ``),
          { method: "GET" }
        );
        // const res = json;
        return {
          data: { id: params.id, ...json.data },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource === "getnewapplicationdetail") {
      try {
        const { json }: any = await httpClient(
          `${API_URL}/common/getnewapplicationdetail` +
          (params.id ? `?subscriberid=${params.id}` : ""),
          { method: "GET" }
        );
        const res = await json;
        return {
          data: { id: params.id, ...res.data },
        };
      } catch (error) {
        throw new Error("something went wrong, try again!");
      }
    }
    if (resource == "getipaddress") {
      try {
        const result = await fetch(`https://geolocation-db.com/json/`, {
          method: "GET",
        });
        const res = await result.json();
        return {
          data: { id: params.id, ...res },
        };
      } catch (error) {
        throw new Error("something went wrong, try again!");
      }
    }
    if (resource == "getapplicationinboxdetails") {
      try {
        const { json, headers, status }: any = await httpClient(
          `${API_URL}/common/getapplicationinboxdetails/${params?.id}`,
          { method: "GET" }
        );
        return {
          data: { id: params.id, ...json },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource == "getidentification") {
      try {
        const { json, headers, status }: any = await httpClient(
          `${API_URL}/applicant/applicant/getidentification/${params.id}`,
          { method: "GET" }
        );
        const response = json;

        // const stateId = response.records.identification_data.state;
        // const selectedState = response.records.states.filter((state) => {
        //   return state.ID == stateId;
        // });
        if (response.records?.address?.length > 0) {
          const updateAddress = await response.records?.address.map(
            async (singleAddress, index) => {
              let states = await response.records.states.filter((state) => {
                if (state.ID == singleAddress.state) {
                  let body = {
                    value: state.ID,
                    label: state.StateName,
                  };

                  singleAddress.state = body;
                }
              });
            }
          );
        }

        const res = {
          ...response.records,
          applicant: {
            ...response.records.applicant,
            otherPhoneCaption: {
              value: response.records.applicant.otherPhoneCaption,
              label: response.records.applicant.otherPhoneCaption,
            },
            alias_status:
              response.records.applicant.Alias.length > 0 ? "Yes" : "No",
            // no_doc_reason: !response.records.identification_data.other_reason
            //   ? "Other"
            //   : !!response.records.identification_data.no_doc_reason
            //   ? response.records.identification_data.no_doc_reason
            //   : "I don't currently have access to document(s), but can provide later",
            no_doc_reason:
              response.records.identification_data.no_doc_reason ||
              "I don't currently have access to document(s), but can provide later",
            availableToUpload_id:
              response.records.identification_data.availableToUpload_id,
            // state:
            //   selectedState?.length > 0
            //     ? {
            //       value: selectedState[0].ID,
            //       label:
            //         selectedState[0].StateShortCode +
            //         " - " +
            //         selectedState[0].StateName,
            //     }
            //     : null,
            // isNameMatched: "",
            // isDOBMatched: "",
          },
          address: !response.records.address.length
            ? [
              {
                id: 0,
                is_current_address: true,
                move_in_date: null,
                move_out_date: null,
                current_rent: null,
                mortgage_rent: null,
              },
            ]
            : response.records.address,
          // ssn_data: {
          //   ...response.records.ssn_data,
          //   availableToUpload_ssn: response.records.ssn_data.file
          //     ? "Yes"
          //     : response.records.ssn_data.no_doc_reason
          //       ? "No"
          //       : response.records.ssn_data.other_reason
          //         ? "No"
          //         : "",
          //   no_doc_reason: response.records.ssn_data.no_doc_reason || "I don't currently have access to document(s), but can provide later",
          // },
        };
        return {
          data: { id: params.id, data: res },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource == "ssnGetidentification") {
      try {
        const { json, headers, status }: any = await httpClient(
          `${API_URL}/applicant/applicant/getidentification/${params.id}`,
          { method: "GET" }
        );
        const response = json;

        if (response.records?.address?.length > 0) {
          const updateAddress = await response.records?.address.map(
            async (singleAddress, index) => {
              let states = await response.records.states.filter((state) => {
                if (state.ID == singleAddress.state) {
                  let body = {
                    value: state.ID,
                    label: state.StateName,
                  };

                  singleAddress.state = body;
                }
              });
            }
          );
        }
        const res = {
          ...response.records,
          ssn_data: {
            ...response.records.ssn_data,
            availableToUpload_ssn: response.records.ssn_data.file
              ? "Yes"
              : response.records.ssn_data.no_doc_reason
                ? "No"
                : response.records.ssn_data.other_reason
                  ? "No"
                  : "",
            no_doc_reason:
              response.records.ssn_data.no_doc_reason ||
              "I don't currently have access to document(s), but can provide later",
          },
        };
        return {
          data: { id: params.id, data: res },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource == "getTandCpageforpayment") {
      try {
        const { json, headers, status }: any = await httpClient(
          `${API_URL}/applicant/stripe/getTandCpageforpayment/${params.id}`,
          { method: "GET" }
        );
        const response = json;
        return {
          data: { id: params.id, data: response },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource == "getpayment") {
      try {
        const { json, headers, status }: any = await httpClient(
          `${API_URL}/applicant/stripe/getpayment/${params.id}`,
          { method: "GET" }
        );
        const response = json;
        return {
          data: { id: params.id, data: response },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource == "contextualgetdetails") {
      try {
        const { json, headers, status }: any = await httpClient(
          `${API_URL}/applicant/contextual/getdetails/${params.id}`,
          { method: "GET" }
        );
        const response = json;
        let labelCount = 0;
        const res = {
          questionsLength: response.records?.length,
          anyone_over_18: response?.records[0]
            ? {
              ...response?.records[0],
              labelCount: response?.records[0].display
                ? labelCount++
                : labelCount,
              labelIndex: labelCount,
            } || {
              display: false,
              required: false,
              response: "no",
            }
            : {},
          co_occupants: response?.records[1]
            ? {
              ...response?.records[1], //2
              // labelIndex: response.records[0].display ? 2 : 1,
              labelCount: response?.records[1].display
                ? labelCount++
                : labelCount,
              labelIndex: labelCount,
            } || {
              display: false,
              required: false,
              response: "no",
            }
            : {},
          is_co_signer: response?.records[2]
            ? {
              ...response?.records[2],

              labelCount: response?.records[2].display
                ? labelCount++
                : labelCount,
              labelIndex: labelCount,
            } || {
              display: false,
              required: false,
              response: "no",
            }
            : {},
          reference: response?.records[3]
            ? {
              ...response?.records[3],

              labelCount: response?.records[3].display
                ? labelCount++
                : labelCount,
              labelIndex: labelCount,
            } || {
              display: false,
              required: false,
              response: "no",
            }
            : {},
          emergency_reference: response?.records[4]
            ? {
              ...response?.records[4],

              labelCount: response?.records[4].display
                ? labelCount++
                : labelCount,
              labelIndex: labelCount,
            } || {
              display: false,
              required: false,
              response: "no",
            }
            : {},
          renters_insurance: response?.records[8]
            ? {
              ...response?.records[8],

              labelCount: response?.records[8].display
                ? labelCount++
                : labelCount,
              labelIndex: labelCount,
            } || {
              display: false,
              required: false,
              response: "no",
            }
            : {},
          eviction_or_nonpayment: response?.records[10]
            ? {
              ...response?.records[10],

              labelCount: response?.records[10].display
                ? labelCount++
                : labelCount,
              labelIndex: labelCount,
            } || {
              display: false,
              required: false,
              response: "no",
            }
            : {}, //TODO: From Contengies
          contingencies: response?.records[11]
            ? {
              ...response?.records[11],

              labelCount: response?.records[11].display
                ? labelCount++
                : labelCount,
              labelIndex: labelCount,
            } || {
              display: false,
              required: false,
              response: "no",
            }
            : {},
          filed_for_bankruptcy: response?.records[13]
            ? {
              ...response?.records[13],

              labelCount: response?.records[13].display
                ? labelCount++
                : labelCount,
              labelIndex: labelCount,
            } || {
              display: false,
              required: false,
              response: "no",
            }
            : {},
          is_student: response?.records[7]
            ? {
              ...response?.records[7],

              labelCount: response?.records[7].display
                ? labelCount++
                : labelCount,
              labelIndex: labelCount,
            } || {
              display: false,
              required: false,
              response: "no",
            }
            : {},
          have_vehicle: response?.records[6]
            ? {
              ...response?.records[6],

              labelCount: response?.records[6].display
                ? labelCount++
                : labelCount,
              labelIndex: labelCount,
            } || {
              display: false,
              required: false,
              response: "no",
            }
            : {},
          have_pet: response?.records[5]
            ? {
              ...response?.records[5],

              labelCount: response?.records[5].display
                ? labelCount++
                : labelCount,
              labelIndex: labelCount,
            } || {
              display: false,
              required: false,
              response: "no",
            }
            : {},
          do_you_smoke: response?.records[12]
            ? {
              ...response?.records[12],

              labelCount: response?.records[12].display
                ? labelCount++
                : labelCount,
              labelIndex: labelCount,
            } || {
              display: false,
              required: false,
              response: "no",
            }
            : {},

          convicted_for_crime: response?.records[9]
            ? {
              ...response?.records[9],

              labelCount: response?.records[9].display
                ? labelCount++
                : labelCount,
              labelIndex: labelCount,
            } || {
              display: false,
              required: false,
              response: "no",
            }
            : {},
        };
        // debugger;
        return {
          data: { id: params.id, ...res },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource == "getsubscribercount") {
      try {
        const { json, headers, status }: any = await httpClient(
          `${API_URL}/admin/getsubscribercount`,
          { method: "GET" }
        );
        const response = json?.data;
        return {
          data: { id: params.id, records: response },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    }

    if (resource == "getqueuecount") {
      try {
        const { json, headers, status }: any = await httpClient(
          `${API_URL}/admin/getqueuecount`,
          { method: "GET" }
        );
        const response = json?.data;
        return {
          data: { id: params.id, records: response },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    }

    if (resource === "getactivedatagraph") {
      try {
        let url = `/account/getactivedatagraph?isday=${params.id}`;
        const { json }: any = await httpClient(`${API_URL}${url}`, {
          method: "GET",
        });
        const res = await json;
        return {
          data: { id: 1, ...res.data },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource === "getgraphdata") {
      try {
        let url = `/account/getgraphdata`;
        const { json }: any = await httpClient(`${API_URL}${url}`, {
          method: "GET",
        });
        const res = await json;
        const newResp = {
          ...res.data,
          osdata: res?.data?.osdata?.map((item: any) => {
            item.name = item.OS;
            item.value = item.Users;

            delete item.OS;
            delete item.Users;
            return item;
          }),
          broswerdata: res?.data?.broswerdata?.map((item: any) => {
            item.name = item.Browser;
            item.value = item.Users;

            delete item.Browser;
            delete item.Users;
            return item;
          }),
        };
        return {
          data: { id: 1, ...newResp },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource === "mlbanklist") {
      try {
        const { json }: any = await httpClient(`${API_URL_DOC}/ml/mlbanklist`, {
          method: "GET",
        });
        const res = await json;
        return {
          data: { id: 1, data: res },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource === "getalldocs") {
      try {
        const { json }: any = await httpClient(
          `${API_URL}/common/getalldocs/${params.id}`,
          { method: "GET" }
        );
        const res = await json;
        return {
          data: { id: 1, data: res?.data },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    //get data policy
    if (resource === "getdatapolicy") {
      try {
        const { json }: any = await httpClient(
          `${API_URL}/account/getdatapolicy/`,
          { method: "GET" }
        );

        // const res = {
        //   data: json.data[0],
        //   total: json.total,
        // };
        const res = await json;
        return {
          data: { id: 1, data: res?.records },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    }

    //get term of use
    if (resource === "gettermsofuse") {
      try {
        const { json }: any = await httpClient(
          `${API_URL}/account/gettermsofuse/`,
          { method: "GET" }
        );

        const res = await json;
        return {
          data: { id: 1, data: res?.records },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource === "registrationpage") {
      try {
        const headers = new Headers();
        headers.append("url", params.id);
        const { json }: any = await httpClient(
          `${API_URL?.replace("/api", "")}/users/registrationpage`,
          { headers: headers, method: "GET" }
        );
        const res = await json;
        return {
          data: { id: 1, data: res },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource === "viewappreport") {
      try {
        const { json }: any = await httpClient(
          `${API_URL}/common/view-app-report`,
          { method: "POST", body: JSON.stringify(params?.id) }
        );
        const res = await json;
        return {
          data: { id: 1, ...res },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource === "getApplicants") {
      try {
        const { json }: any = await httpClient(
          `${API_URL}/subscriber/get-applicants?ApplicationNumber=${params?.id}`,
          { method: "GET" }
        );
        const res = await json;
        return {
          data: { id: 1, ...res },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource === "getStates") {
      try {
        const { json }: any = await httpClient(`${API_URL}/common/getStates`, {
          method: "GET",
        });
        const res = await json;
        return {
          data: { id: 1, ...res },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource === "application-profile") {
      try {
        const { json }: any = await httpClient(
          `${API_URL}/subscriber/application-profile/${params?.id}`,
          { method: "GET" }
        );
        const res = await json;
        return {
          data: { id: 1, ...res },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource === "lease-template") {
      try {
        const { json }: any = await httpClient(
          `${API_URL}/subscriber/lease-template/${params?.id}`,
          { method: "GET" }
        );
        const res = await json;
        return {
          data: { id: 1, ...res },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource === "get-third-party-apps") {
      try {
        const { json }: any = await httpClient(
          `${API_URL}/subscriber/get-third-party-apps/${params?.id}`,
          { method: "GET" }
        );
        const res = await json;
        return {
          data: { id: 1, ...res },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource === "subscriber-screening-company") {
      try {
        const { json }: any = await httpClient(
          `${API_URL}/subscriber/subscriber-screening-company/${params?.id}`,
          { method: "GET" }
        );
        const res = await json;
        return {
          data: { id: 1, ...res },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource === "adverse-letter") {
      try {
        const { json }: any = await httpClient(
          `${API_URL}/subscriber/adverse-letter/${params?.id}`,
          { method: "GET" }
        );
        const res = await json;
        return {
          data: { id: 1, ...res },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource === "get-property-details") {
      try {
        const { json }: any = await httpClient(
          `${API_URL}/subscriber/${params?.id?.type === "Office"
            ? `get-office`
            : `get-property-details`
          }?${params?.id?.type === "Office" ? `officeID` : `propertyID`}=${params?.id?.propertyID
          }&subscriberID=${params?.id?.subscriberID}`,
          { method: "GET" }
        );
        const res = await json;
        return {
          data: { id: 1, ...res },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource === "integration-setting-rule") {
      try {
        const { json }: any = await httpClient(
          `${API_URL}/subscriber/${params?.id?.type === "Office"
            ? `get-office`
            : `get-property-details`
          }?${params?.id?.type === "Office" ? `officeID` : `propertyID`}=${params?.id?.propertyID
          }&subscriberID=${params?.id?.subscriberID}`,
          { method: "GET" }
        );
        const res = await json;
        return {
          data: { id: 1, ...res },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource === "list-third-party-apps") {
      try {
        const { json }: any = await httpClient(
          `${API_URL}/subscriber/list-third-party-apps`,
          { method: "GET" }
        );
        const res = await json;
        return {
          data: { id: 1, ...res },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource === "screening-company") {
      try {
        const { json }: any = await httpClient(
          `${API_URL}/subscriber/screening-company`,
          { method: "GET" }
        );
        const res = await json;
        return {
          data: { id: 1, ...res },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource === "company-type") {
      try {
        const { json }: any = await httpClient(
          `${API_URL}/subscriber/company-type`,
          { method: "GET" }
        );
        const res = await json;
        return {
          data: { id: 1, ...res },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource === "get-subscriber") {
      try {
        const { json }: any = await httpClient(
          `${API_URL}/subscriber/get-subscriber/${params?.id}`,
          { method: "GET" }
        );
        const res = await json;
        return {
          data: { id: 1, data: res?.records },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource === "subscriber-third-party-menu") {
      try {
        const { json }: any = await httpClient(
          `${API_URL}/subscriber/subscriber-third-party-menu?subscriberID=${params?.id}`,
          { method: "GET" }
        );
        const res = await json;
        return {
          data: { id: 1, ...res },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource === "get-stripe-details") {
      try {
        const { json }: any = await httpClient(
          `${API_URL}/subscriber/getStripe/${params?.id?.type === "Office" ? "office" : "property"
          }?ID=${params?.id?.id}`,
          { method: "GET" }
        );
        const res = await json;
        return {
          data: { id: 1, ...res },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource === "getDownloadDocumentsMessage") {
      try {
        const { json }: any = await httpClient(
          `${API_URL}/applicant/applicant/getdownloaddocuments/${params?.id}`,
          { method: "GET" }
        );
        const res = await json;
        return {
          data: { id: 1, ...res },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource === "getlookbacks") {
      try {
        const { json }: any = await httpClient(
          `${API_URL}/subscriber/getlookbacks?applicationnumber=${params?.id}`,
          { method: "GET" }
        );
        const res = await json;
        return {
          data: { id: 1, ...res },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource === "get-subscriber-configurations") {
      try {
        const { json }: any = await httpClient(
          `${API_URL}/subscriber/subscriber-configurations/${params?.id}`,
          { method: "GET" }
        );
        const res = await json;
        return {
          data: { id: 1, ...res },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    return baseDataProvider.getOne(resource, params);
  },
  delete: async (resource, params) => {
    if(resource=="getocrqueuelist"){
      try {
        const { json, headers, status }: any = await httpClient(
          API_URL + "/admin/removefromqueue?id=" + params.id,
          {
            method: "POST",
          }
        );
        const r = json;
        const res = {
          data: { id: params.id },
        };
        return res;
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource == "deletedDocument") {
      try {
        const { json, headers, status }: any = await httpClient(
          API_URL_DOC + "/document/deletedDocument/" + params.id,
          {
            method: "DELETE",
          }
        );
        const r = json;
        const res = {
          data: { id: params.id },
        };
        return res;
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource.includes("/applicant/")) {
      try {
        const { json, headers, status }: any = await httpClient(
          API_URL_DOC + `${resource}/${params.id}`,
          {
            method: "DELETE",
          }
        );
        const r = json;
        const res = {
          data: { id: "" },
        };
        return res;
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    return baseDataProvider.delete(resource, params);
  },
  getList: async (resource, params) => {
    const _start = (params.pagination.page - 1) * params.pagination.perPage;
    const _end = params.pagination.page * params.pagination.perPage;
    if (resource === "getocrqueuelist") {
      try {
        // debugger;
        console.log("params", params);
        const _start = (params.pagination.page - 1) * params.pagination.perPage;
        const _end = params.pagination.page * params.pagination.perPage;
        const { json }: any = await httpClient(
          `${API_URL}/admin/getocrqueuelist?_sort=${params?.sort.field || ""}&_order=${params?.sort.order || ""}&_start=${_start || ""}&_end=${_end || ""}&q=${params.filter?.q || ""}`,
          {
            method: "GET",

          }
        );

        return json;
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource == 'GETDataFiled') {
      try {
        const { json, headers, status }: any = await httpClient(
          API_URL + `/common/getdatafields`
        );

        const data = Object.keys(json.records).map((key) => { return { id: key, label: key, value: json.records[key] } });
        const res = {
          data: data,
          total: data.length,
        };
        return res;
      } catch (e) {
        throw new Error("something went wrong, try again!");
      }
    }
    if (resource == 'GETMLDetailsList') {
      try {
        // suggestion api to change post to get list 
        // debugger;
        const raw = JSON.stringify({
          DocumentTypeID: params.filter.DocumentTypeID
        });
        const { json, headers, status }: any = await httpClient(
          API_URL_DOC + `/document/GetMLclassDetails`,
          {
            method: "POST",
            body: raw,
          }
        );
        const res = {
          data: json.records.map((item) => ({ id: item.ID, ...item })),
          total: json.records.length,
        };
        return res;
      }
      catch (e) {
        throw new Error("something went wrong, try again!");
      }
    }
    if (resource == 'gettermsandcondition') {
      try {
        const { json }: any = await httpClient(
          API_URL + `/subscriber/gettermsandcondition${params?.filter?.SubscriberID ? `?SubscriberId=${params?.filter?.SubscriberID}` : ''} `
        );
        const res = {
          data: json.data.map((item, index) => ({ id: index + 1, ...item })),
          total: json.data.length,
        };
        return res;
      } catch (e) {
        throw new Error("something went wrong, try again!");
      }
    }
    if (resource == 'gettermsandcondition_admin') {
      try {
        const { json }: any = await httpClient(
          API_URL + `/admin/gettermsandcondition`
        );
        const res = {
          data: json.data.map((item, index) => ({ id: index + 1, ...item })),
          total: json.data.length,
        };
        return res;
      } catch (e) {
        throw new Error("something went wrong, try again!");
      }
    }
    if (resource === "Property") {
      try {
        // subscriber/get-property-details/1
        const { json }: any = await httpClient(
          API_URL +
          `/subscriber/get-property-details?subscriberID=${params?.filter?.subscriberID
          }&_sort=${params?.sort?.field || ""}&_order=${params?.sort?.order || ""
          }&_start=${_start || ""}&_end=${_end || ""}&_search=${params?.filter?.q || ""
          }&status=${params?.filter?.propertyStatus
            ? params?.filter?.propertyStatus.toString()
            : ""
          }`
        );
        const res = {
          data: json.data,
          total: json.total,
        };
        return res;
      } catch (e) {
        throw new Error("something went wrong, try again!");
      }
    }
    //copy url
    else if (resource === "getcopyurl") {
      try {
        const { json }: any = await httpClient(
          API_URL +
          `/common/getcopyurl?Realestate=${params.filter.Realestate}&Urltype=${params.filter.urltype}` +
          (params?.filter?.subscriberID
            ? `&subscriberID=${params?.filter?.subscriberID}`
            : "")
        );
        const res = {
          data: json.data,
          total: json.total,
        };
        return res;
      } catch (e) {
        throw new Error("something went wrong, try again!");
      }
    } else if (resource === "getglobalsearch") {
      try {
        const _start = (params.pagination.page - 1) * params.pagination.perPage;
        const _end = params.pagination.page * params.pagination.perPage;
        const modifyFilterValues = { ...params.filter }
        if (params?.filter?.subscriberid?.value != null && params?.filter?.subscriberid?.value != undefined) {
          modifyFilterValues.subscriberid = params?.filter?.subscriberid?.value;
        }
        const filterValue = objectToQueryString(modifyFilterValues);
        // debugger;
        const { json }: any = await httpClient(
          `${API_URL}/admin/getglobalsearch?_sort=${params?.sort.field || ''}&_order=${params?.sort.order || ''}&_start=${_start || ''}&_end=${_end || ''}&` + filterValue,
          { method: "GET" }
        );
        const r = await json;
        const data = r.data.map(item => ({ ...item, IsRevamp: item.ApplicationType == 'Application' ? true : item.IsRevamp, isAppCreatedUsingNet: !item.IsRevamp }));
        const res = { ...r, data: data }
        return res;
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource == "mlclassdetails") {
      try {
        const { status, headers, body, json }: any = await httpClient(
          `${API_URL_DOC}/ml/mlclassdetails?doc_typeid=${params?.filter?.doc_typeid || 3
          }&_sort=${params?.sort.field || ""}&_order=${params?.sort.order || ""
          }&_start=${_start || ""}&_end=${_end || ""}&q=${params.filter?.q || ""
          }&page=${params.pagination?.page || 1}&perPage=${params.pagination?.perPage
          }`,
          { method: "GET" }
        );
        const res = json;
        return { data: res, total: headers.get("X-Total-Count") };
      } catch (e: any) {
        throw new Error(e.message);
      }
    }

    if (resource == "getclassretrainlogs") {
      try {
        const { status, headers, body, json }: any = await httpClient(
          `${API_URL_DOC}/ml/getclassretrainlogs?doc_typeid=${params?.filter?.doc_typeid || 1
          }&_sort=${params?.sort.field || ""}&_order=${params?.sort.order || ""
          }&_start=${_start || ""}&_end=${_end || ""}&q=${params.filter?.q || ""
          }&page=${params.pagination?.page || 1}&perPage=${params.pagination?.perPage
          }`,
          { method: "GET" }
        );
        const res = json;
        return { data: res, total: headers.get("X-Total-Count") };
      } catch (e: any) {
        throw new Error(e.message);
      }
    }

    if (resource == "getFlaggedDocuments") {
      try {
        const { status, headers, body, json }: any = await httpClient(
          `${API_URL_DOC}/ml/getFlaggedDocuments?doc_typeid=${params?.filter?.doc_typeid || 1
          }&_sort=${params?.sort.field || ""}&_order=${params?.sort.order || ""
          }&_start=${_start || ""}&_end=${_end || ""}&q=${params.filter?.q || ""
          }&page=${params.pagination?.page || 1}&perPage=${params.pagination?.perPage
          }`,
          { method: "GET" }
        );
        const res = json;
        return { data: res, total: headers.get("X-Total-Count") };
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource == "getFlaggedSOCRData") {
      try {
        const { status, headers, body, json }: any = await httpClient(
          `${API_URL_DOC}/ml/getFlaggedSOCRData?doc_typeid=${params?.filter?.doc_typeid || 1
          }&_sort=${params?.sort.field || ""}&_order=${params?.sort.order || ""
          }&_start=${_start || ""}&_end=${_end || ""}&q=${params.filter?.q || ""
          }&page=${params.pagination?.page || 1}&perPage=${params.pagination?.perPage
          }`,
          { method: "GET" }
        );
        const res = json;
        return { data: res, total: headers.get("X-Total-Count") };
      } catch (e: any) {
        throw new Error(e.message);
      }
    }

    if (resource == "getFlagSOCRStatus") {
      try {
        const { status, headers, body, json }: any = await httpClient(
          `${API_URL_DOC}/ml/getFlagSOCRStatus?doc_typeid=${params?.filter?.doc_typeid || 1
          }&_sort=${params?.sort.field || ""}&_order=${params?.sort.order || ""
          }&_start=${_start || ""}&_end=${_end || ""}&q=${params.filter?.q || ""
          }&page=${params.pagination?.page || 1}&perPage=${params.pagination?.perPage
          }`,
          { method: "GET" }
        );
        const res = json;
        return { data: res, total: headers.get("X-Total-Count") };
      } catch (e: any) {
        throw new Error(e.message);
      }
    }

    if (resource === "getloginactivitylogs") {
      try {
        const { json }: any = await httpClient(
          `${API_URL}/account/getloginactivitylogs?_sort=${params?.sort.field || ""
          }&_order=${params?.sort.order || ""}&_start=${_start || ""}&_end=${_end || ""
          }&search_by=${params.filter?.q || ""}&rangefrom=${params.filter.start_date || ""
          }&rangeto=${params.filter.end_date || ""}&lastdays=${params.filter?.lastdays || ""
          }&city=${params.filter?.city || ""}&state=${params.filter?.state || ""
          }&country=${params.filter?.country || ""}`,
          { method: "GET" }
        );
        const res = json;
        return res;
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource === "getarchivedcancelled") {
      try {
        const { json }: any = await httpClient(
          `${API_URL}/subscriber/getarchivedcancelled?_sort=${params?.sort.field || ""
          }&_order=${params?.sort.order || ""}&_start=${_start || ""}&_end=${_end || ""
          }&q=${params.filter?.q || ""}&agent=${params.filter?.Agent || ""
          }&app_invitation_type=${params.filter?.app_invitation_type || ""
          }&property=${params.filter?.Office || params.filter.Property || ""
          }&rangefrom=${params.filter.start_date || ""}&rangeto=${params.filter.end_date || ""
          }&applicationstatus=${params.filter.ApplicationStatus
            ? params.filter.ApplicationStatus.toString()
            : ""
          }&Floor=${params.filter.Floor || ""}` +
          (params.filter.subscriberid
            ? `&subscriberid=${params.filter.subscriberid}`
            : ""),
          { method: "GET" }
        );
        const res = json;
        return res;
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource == "deletedDocument") {
      try {
        const _start = (params.pagination.page - 1) * params.pagination.perPage;
        const _end = params.pagination.page * params.pagination.perPage;

        const { json }: any = await httpClient(
          `${API_URL_DOC}/document/deletedDocument/${params.filter.trn_id}?_start=${_start}&_end=${_end}`
        );
        const res = json;
        // const res1 = {
        //   data: res,
        //   total: res.length,
        // };
        // return res1;
        return res;
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (
      resource === "getAllBlockSections" ||
      resource === "getAllColumnSections"
    ) {
      try {
        const { json }: any = await httpClient(
          API_URL_DOC + `/ml/${resource}`,
          {
            method: "GET",
          }
        );
        const r = json;
        const res = {
          data: [...r?.records],
          total: r?.records.length,
        };
        return res;
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource === "getAllFieldsByDocumentId") {
      try {
        const { json }: any = await httpClient(
          API_URL_DOC + `/ml/getAllFieldsByDocumentId/6`,
          {
            method: "GET",
          }
        );
        const r = json;
        //
        const res = {
          data: [...r.records],
          total: r.records.length,
        };
        return res;
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource === "getAllImages") {
      const { pagination, filter } = params;
      const end = pagination.perPage * (pagination.page + 1);
      const start = pagination.perPage * pagination.page;
      try {
        const { json, headers }: any = await httpClient(
          API_URL_DOC +
          `/ml/getAllImages?_end=${end}&_order=DESC&_sort=id&_start=${start}&_id=${filter.id}`,
          {
            method: "GET",
          }
        );
        const r = json;
        const total = await headers.get("X-Total-Count");
        const res = {
          data: [...r],
          total: total,
        };
        return res;
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource === "getsubmittedauditlog") {
      try {
        // https://uatapiml.dvapply.com/api/subscriber/getsubmittedauditlog/23820
        const _start = (params.pagination.page - 1) * params.pagination.perPage;
        const _end = params.pagination.page * params.pagination.perPage;
        const { json }: any = await httpClient(
          `${API_URL}/subscriber/getsubmittedauditlog/${params.filter.applicantId
          }?_sort=${params?.sort.field || ""}&_order=${params?.sort.order || ""
          }&_start=${_start || ""}&_end=${_end || ""}&q=${params.filter?.q || ""
          }`,
          { method: "GET" }
        );
        const res = await json;
        return res;
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource === "getsubscriberinbox") {

      if (params.filter.ApplicationStatus?.[0] === 'Completed-Conditionally Approved') {
        params.filter.ApplicationStatus = 'CompletedConditionallyApproved';
      }
      try {
        // https://uatapimlbeta.dvapply.com/ml/mlclassdetails?_end=10&_order=ASC&_sort=ClassName&_start=0&q=d
        const _start = (params.pagination.page - 1) * params.pagination.perPage;
        const _end = params.pagination.page * params.pagination.perPage;
        const { json }: any = await httpClient(
          `${API_URL}/subscriber/getsubscriberinbox?_sort=${params?.sort.field || ""
          }&_order=${params?.sort.order || ""}&_start=${_start || ""}&_end=${_end || ""
          }&q=${params.filter?.q || ""}&agent=${params.filter?.Agent || ""
          }&app_invitation_type=${params.filter?.app_invitation_type || ""
          }&property=${params.filter?.Office || params.filter.Property || ""
          }&rangefrom=${params.filter.start_date || ""}&rangeto=${params.filter.end_date || ""
          }&applicationstatus=${params.filter.ApplicationStatus
            ? params.filter.ApplicationStatus.toString()
            : ""
          }&Floor=${params.filter.Floor || ""}` +
          (params.filter.subscriberid
            ? `&subscriberid=${params.filter.subscriberid}`
            : ""),
          { method: "GET" }
        );
        const r = await json;
        const data = r.data.map(item => ({ ...item, IsRevamp: params?.filter?.app_invitation_type == 1 ? true : item.IsRevamp, isAppCreatedUsingNet: !item.IsRevamp }));
        const res = { ...r, data: data }
        return res;
      } catch (e: any) {
        throw new Error(e.message);
      }
    }

    if (resource === "getAdminAllinboxType") {
      try {
        const _start = (params.pagination.page - 1) * params.pagination.perPage;
        const _end = params.pagination.page * params.pagination.perPage;
        if (params.filter?.inbox_type == 'ATS_SCREENING_QUEUE') {
          const filterValue = objectToQueryString({ ...params.filter, 'app_invitation_type': 1, subscriberid: params?.filter?.subscriberid?.value || '' });
          const { json }: any = await httpClient(`${API_URL}/admin/getatsqueue?_sort=${params?.sort.field || ''}&_order=${params?.sort.order || ''}&_start=${_start || ''}&_end=${_end || ''}&` + filterValue, { method: 'GET' });
          // const data = json.data.map(item => ({ ...item, IsRevamp: true }));
          const data = json.data.map(item => ({ ...item, IsRevamp: true, isAppCreatedUsingNet: !item.IsRevamp }));
          const res = { ...json, data: data }
          return res;
        } else if (params.filter?.inbox_type == 'ATS_LAST_30_DAYS') {
          if (params?.filter?.lastApplicationInDays?.value == undefined) {
            delete params.filter.lastApplicationInDays
          } else {
            params.filter.lastApplicationInDays = params?.filter?.lastApplicationInDays?.value
          }
          const filterValue = objectToQueryString({ ...params.filter, 'app_invitation_type': 1, subscriberid: params?.filter?.subscriberid?.value || '' });
          const { json }: any = await httpClient(`${API_URL}/admin/getlastthirtydaysqueue?_sort=${params?.sort.field || ''}&_order=${params?.sort.order || ''}&_start=${_start || ''}&_end=${_end || ''}&` + filterValue, { method: 'GET' });
          const data = json.data.map(item => ({ ...item, IsRevamp: true, isAppCreatedUsingNet: !item.IsRevamp }));
          return { ...json, data: data }
        } else if (params.filter?.inbox_type == 'DOCUPLOAD_LAST_30_DAYS') {
          // debugger;
          const filterValue = objectToQueryString({ ...params.filter, 'app_invitation_type': 2, subscriberid: params?.filter?.subscriberid?.value || '' });
          const { json }: any = await httpClient(`${API_URL}/admin/getlastthirtydaysqueue?_sort=${params?.sort.field || ''}&_order=${params?.sort.order || ''}&_start=${_start || ''}&_end=${_end || ''}&` + filterValue, { method: 'GET' });
          const data = json.data.map(item => ({ ...item, IsRevamp: item.IsRevamp, isAppCreatedUsingNet: !item.IsRevamp }));
          return { ...json, data: data }
        } else {
          const filterValue = objectToQueryString({ ...params.filter, 'app_invitation_type': 1, subscriberid: params?.filter?.subscriberid?.value || '' });
          const { json }: any = await httpClient(`${API_URL}/admin/getreviewqueue?_sort=${params?.sort.field || ''}&_order=${params?.sort.order || ''}&_start=${_start || ''}&_end=${_end || ''}&` + filterValue, { method: 'GET' });
          const res = {
            ...json,
            data: json?.data?.map((item) => ({
              ...item,
              appInDAReviewQueue: item?.ApplicationType === "Application",
              ApplicationType: "Docupload",
            })),
          };
          return res;
        }
      } catch (e: any) {
        throw new Error(e.message);
      }
    }

    if (resource === "getadmininbox") {
      try {
        // https://uatapimlbeta.dvapply.com/ml/mlclassdetails?_end=10&_order=ASC&_sort=ClassName&_start=0&q=d
        const _start = (params.pagination.page - 1) * params.pagination.perPage;
        const _end = params.pagination.page * params.pagination.perPage;
        // &q=${params.filter?.q || ''}&agent=${params.filter?.Agent || ''}&app_invitation_type=${params.filter?.app_invitation_type || ''}&property=${params.filter?.Office || params.filter.Property || ''}&rangefrom=${params.filter.start_date || ''}&rangeto=${params.filter.end_date || ''}&applicationstatus=${params.filter.ApplicationStatus ? params.filter.ApplicationStatus.toString() : ''}&subscriberid=${params.filter.subscriberid}&Floor=${params.filter.Floor || ''}
        const { json }: any = await httpClient(
          `${API_URL}/admin/getreviewqueue?_sort=${params?.sort.field || ""
          }&_order=${params?.sort.order || ""}&_start=${_start || ""}&_end=${_end || ""
          }&` + objectToQueryString(params.filter),
          { method: "GET" }
        );

        const res = {
          ...json,
          data: json?.data?.map((item) => ({
            ...item,
            appInDAReviewQueue: item?.ApplicationType === "Application",

          })),
        };
        return res;
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource === "getatsqueue") {
      try {
        const { json }: any = await httpClient(`${API_URL}/admin/getatsqueue?_sort=${params?.sort.field || ''}&_order=${params?.sort.order || ''}&_start=${_start || ''}&_end=${_end || ''}&` + objectToQueryString(params.filter), { method: 'GET' });
        const data = json.data.map(item => ({ ...item, IsRevamp: true, isAppCreatedUsingNet: !item.IsRevamp }));
        const res = { ...json, data: data }
        return res;
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource === "getlastthirtydaysqueue") {
      try {
        // https://uatapimlbeta.dvapply.com/ml/mlclassdetails?_end=10&_order=ASC&_sort=ClassName&_start=0&q=d
        const _start = (params.pagination.page - 1) * params.pagination.perPage;
        const _end = params.pagination.page * params.pagination.perPage;
        // &q=${params.filter?.q || ''}&agent=${params.filter?.Agent || ''}&app_invitation_type=${params.filter?.app_invitation_type || ''}&property=${params.filter?.Office || params.filter.Property || ''}&rangefrom=${params.filter.start_date || ''}&rangeto=${params.filter.end_date || ''}&applicationstatus=${params.filter.ApplicationStatus ? params.filter.ApplicationStatus.toString() : ''}&subscriberid=${params.filter.subscriberid}&Floor=${params.filter.Floor || ''}
        const filterValue = objectToQueryString(params.filter);
        const { json }: any = await httpClient(`${API_URL}/admin/getlastthirtydaysqueue?_sort=${params?.sort.field || ''}&_order=${params?.sort.order || ''}&_start=${_start || ''}&_end=${_end || ''}&` + filterValue, { method: 'GET' });
        const data = json.data.map(item => ({ ...item, IsRevamp: params?.filter?.app_invitation_type == 1 ? true : item.IsRevamp, isAppCreatedUsingNet: !item.IsRevamp }));
        const res = { ...json, data: data }
        return res;
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource === "getsubscriberusage") {
      try {
        // https://uatapimlbeta.dvapply.com/ml/mlclassdetails?_end=10&_order=ASC&_sort=ClassName&_start=0&q=d
        const _start = (params.pagination.page - 1) * params.pagination.perPage;
        const _end = params.pagination.page * params.pagination.perPage;
        const { json }: any = await httpClient(
          `${API_URL}/admin/getsubscriberusage?_sort=${params?.sort.field || ""
          }&_order=${params?.sort.order || ""}&_start=${_start || ""}&_end=${_end || ""
          }`,
          { method: "GET" }
        );
        const res = await json;
        return res;
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource === "getpredefinemessage") {
      try {
        const { json }: any = await httpClient(
          `${API_URL}/subscriber/getpredefinemessage`,
          { method: "GET" }
        );
        const res = await json;
        return { ...res, data: res.data[0] };
        // return {...res,data:res.data.map(item=>({...item,id:item.ID}))};
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource === "get_subscriber_inbox_detials") {
      try {
        const _start = (params.pagination.page - 1) * params.pagination.perPage;
        const _end = params.pagination.page * params.pagination.perPage;
        const { json }: any = await httpClient(
          `${API_URL}/subscriber/getsubscriberinboxdetails?ApplicationNumber=${params.filter.ApplicationNumber}` +
          (params.filter.subscriberid
            ? `&subscriberid=${params.filter.subscriberid}`
            : ""),
          { method: "GET" }
        );
        const res = await json;
        return res;
        // return {
        //   data:{id:1,...res.data}
        // };
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource === "getagentlist") {
      try {
        const { json }: any = await httpClient(
          `${API_URL}/common/getagentlist?subscriberid=${params.filter.SubscriberID}&url=${params.filter.url}`,
          { method: "GET" }
        );
        const res = json;
        const finalRes = {
          data: res.data.map((item) => ({ ...item, id: item.UserID })),
          total: res.total,
        };
        return finalRes;
      } catch (e: any) {
        throw new Error(e.message);
      }
    }

    if (resource == "getfloorlist") {
      try {
        const { json }: any = await httpClient(
          `${API_URL}/common/getfloorlist?subscriberid=${params.filter.SubscriberID}&propertyid=${params.filter.PropertyID}`,
          { method: "GET" }
        );
        const res = await json;
        const finalRes = {
          data: res.data.map((item) => ({ ...item, id: item.ID })),
          total: res.total,
        };
        return finalRes;
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource == "getunitlist") {
      try {
        const { json }: any = await httpClient(
          `${API_URL}/common/getunitlist?subscriberid=${params.filter.SubscriberID}&floorplanid=${params.filter.PropertyID}`,
          { method: "GET" }
        );
        const res = json;
        const finalRes = {
          data: res.data.map((item) => ({ ...item, id: item.ID })),
          total: res.total,
        };
        return finalRes;
      } catch (error) {
        throw new Error("something went wrong, try again!");
      }
    }
    if (resource == "getsubscribers") {
      try {
        const { json }: any = await httpClient(
          `${API_URL}/common/getsubscribers`,
          { method: "GET" }
        );
        const res = await json;
        const finalRes = {
          data: res.data.map((item) => ({ ...item, id: item.id })),
          total: res.total,
        };
        return finalRes;
      } catch (error) {
        throw new Error("something went wrong, try again!");
      }
    }
    if (resource == "getlandlorddetail") {
      try {
        const { json }: any = await httpClient(
          `${API_URL}/common/getlandlorddetail?_sort=${params?.sort.field || ""
          }&subscriberid=${params?.filter?.subscriberid}&applicationnumber=${params?.filter?.ApplicationNumber
          }`,
          { method: "GET" }
        );
        const res = await json;
        const finalRes = {
          data: res.data.map((item) => ({ ...item, id: item.id })),
          total: res.total,
        };
        return finalRes;
      } catch (error) {
        throw new Error("something went wrong, try again!");
      }
    }
    if (resource === "viewNotes") {
      try {
        const { json }: any = await httpClient(
          `${API_URL}/subscriber/viewNotes/${params?.filter?.ApplicantID}`,
          {
            method: "GET",
          }
        );
        const res = await json;
        const finalRes = {
          data: res.data.map((item) => ({ ...item, id: item.id })),
          total: res.total,
        };

        return finalRes;
        // return res;
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource === "get-floor") {
      try {
        const { json }: any = await httpClient(
          API_URL +
          `/subscriber/get-floor?subscriberID=${params?.filter?.subscriberID
          }&propertyID=${params?.filter?.propertyID ? params?.filter?.propertyID : ""
          }&floorID=${params?.filter?.floorID ? params?.filter?.floorID : ""
          }&_sort=${params?.sort?.field || ""}&_order=${params?.sort?.order || ""
          }&_start=${_start || ""}&_end=${_end || ""}&_search=${params?.filter?.q_floor || ""
          }&status=${params?.filter?.floorStatus
            ? params?.filter?.floorStatus.toString()
            : ""
          }`
        );
        const res = {
          data: json.data,
          total: json.total,
        };
        return res;
      } catch (e) {
        throw new Error("something went wrong, try again!");
      }
    }
    if (resource === "get-unit") {
      try {
        const { json }: any = await httpClient(
          API_URL +
          `/subscriber/get-unit?subscriberID=${params?.filter?.subscriberID
          }&propertyID=${params?.filter?.propertyID ? params?.filter?.propertyID : ""
          }&unitID=${params?.filter?.unitID ? params?.filter?.unitID : ""
          }&_sort=${params?.sort?.field || ""}&_order=${params?.sort?.order || ""
          }&_start=${_start || ""}&_end=${_end || ""}&_search=${params?.filter?.q_units || ""
          }&status=${params?.filter?.unitsStatus
            ? params?.filter?.unitsStatus.toString()
            : ""
          }`
        );
        const res = {
          data: json.data,
          total: json.total,
        };
        return res;
      } catch (e) {
        throw new Error("something went wrong, try again!");
      }
    }
    if (resource === "subscriber-list") {
      try {
        console.log(
          "🚀 ~ file: DataProvidersAts.ts:1417 ~ getList: ~ params.filter:ss",
          params
        );
        const { json }: any = await httpClient(
          API_URL +
          `/subscriber/subscriber-list?&_sort=${params?.sort?.field || ""
          }&_order=${params?.sort?.order || ""}&_start=${_start || ""}&_end=${_end || ""
          }&_search=${params?.filter?.q || ""}&_filter=${params?.filter?.subscribersStatus
            ? params?.filter?.subscribersStatus?.toString()
            : ""
          }`
        );
        const res = {
          data: json.data,
          total: json.total,
        };
        return res;
      } catch (e) {
        throw new Error("something went wrong, try again!");
      }
    }
    if (resource === "integration-setting-rule") {
      try {
        console.log(
          "🚀 ~ file: DataProvidersAts.ts:1417 ~ getList: ~ params.filter:ss",
          params
        );
        const { json }: any = await httpClient(
          API_URL +
          `/subscriber/integration-setting-rule?subscriberID=${params?.filter?.subscriberID
          }&ThirdPartyAppID=${params?.filter?.thirdPartyAppId}&ID=${params?.filter?.id ? params?.filter?.id : ""
          }&_search=${params?.filter?.q || ""}&_sort=${params?.sort?.field || ""
          }&_order=${params?.sort?.order || ""}`
        );
        const res = {
          data: json.data,
          total: json.total,
        };
        return res;
      } catch (e) {
        throw new Error("something went wrong, try again!");
      }
    }
    if (resource === "integration-setting-rule-entrata") {
      try {
        console.log(
          "🚀 ~ file: DataProvidersAts.ts:1417 ~ getList: ~ params.filter:ss",
          params
        );
        const { json }: any = await httpClient(
          API_URL +
          `/subscriber/integration-setting-rule?subscriberID=${params?.filter?.subscriberID
          }&ThirdPartyAppID=${params?.filter?.thirdPartyAppId}&ID=${params?.filter?.id ? params?.filter?.id : ""
          }&_search=${params?.filter?.q_entrata || ""}&_sort=${params?.sort?.field || ""
          }&_order=${params?.sort?.order || ""}&_filter=${params?.filter?.entrataStatus
            ? params?.filter?.entrataStatus.toString()
            : ""
          }`
        );
        const res = {
          data: json.data,
          total: json.total,
        };
        return res;
      } catch (e) {
        throw new Error("something went wrong, try again!");
      }
    }
    if (resource === "integration-setting-rule-yardi") {
      try {
        console.log(
          "🚀 ~ file: DataProvidersAts.ts:1417 ~ getList: ~ params.filter:ss",
          params
        );
        const { json }: any = await httpClient(
          API_URL +
          `/subscriber/integration-setting-rule?subscriberID=${params?.filter?.subscriberID
          }&ThirdPartyAppID=${params?.filter?.thirdPartyAppId}&ID=${params?.filter?.id ? params?.filter?.id : ""
          }&_search=${params?.filter?.q_yardi || ""}&_sort=${params?.sort?.field || ""
          }&_order=${params?.sort?.order || ""}&_filter=${params?.filter?.yardiStatus
            ? params?.filter?.yardiStatus.toString()
            : ""
          }`
        );
        const res = {
          data: json.data,
          total: json.total,
        };
        return res;
      } catch (e) {
        throw new Error("something went wrong, try again!");
      }
    }
    if (resource === "hierarchy-levels-list") {
      try {
        const { json }: any = await httpClient(
          API_URL +
          `/subscriber/hierarchy-levels?subscriberID=${params?.filter?.subscriberID
          }&id=${params?.filter?.id || ""}&_sort=${params?.sort?.field || ""
          }&_order=${params?.sort?.order || ""}&_start=${_start || ""}&_end=${_end || ""
          }&_search=${params?.filter?.q_hierarchy || ""}&_filter=${params?.filter?.hierarchyStatus
            ? params?.filter?.hierarchyStatus?.toString()
            : ""
          }`
        );
        const res = {
          data: json.data,
          total: json.total,
        };
        return res;
      } catch (e) {
        throw new Error("something went wrong, try again!");
      }
    }
    if (resource === "get-office") {
      try {
        const { json }: any = await httpClient(
          API_URL +
          `/subscriber/get-office?subscriberID=${params?.filter?.subscriberID
          }&_sort=${params?.sort?.field || ""}&_order=${params?.sort?.order || ""
          }&_start=${_start || ""}&_end=${_end || ""}&_search=${params?.filter?.q || ""
          }&status=${params?.filter?.propertyStatus
            ? params?.filter?.propertyStatus.toString()
            : ""
          }`
        );
        const res = {
          data: json.data,
          total: json.total,
        };
        return res;
      } catch (e) {
        throw new Error("something went wrong, try again!");
      }
    }
    if (resource === "application-logs") {
      try {
        console.log(
          "🚀 ~ file: DataProvidersAts.ts:1417 ~ getList: ~ params.filter:ss",
          params
        );
        const { json }: any = await httpClient(
          API_URL +
          `/subscriber/application-logs?applicationNumber=${params?.filter?.applicationNumber
          }&applicantID=${params?.filter?.applicantID}&_sort=${params?.sort?.field || ""
          }&_order=${params?.sort?.order || ""}&_start=${_start || ""}&_end=${_end || ""
          }&_search=${params?.filter?.q_Logs || ""}&_filter=${params?.filter?.subscribersStatus
            ? params?.filter?.subscribersStatus?.toString()
            : ""
          }`
        );
        const res = {
          data: json.data,
          total: json.total,
        };
        return res;
      } catch (e) {
        throw new Error("something went wrong, try again!");
      }
    }
    if (resource === "pms-logs") {
      try {
        console.log("🚀 ~ file: DataProvidersAts.ts:1417 ~ getList: ~ params.filter:ss", params)
        const { json }: any = await httpClient(API_URL + `/subscriber/get-third-party-logs?ApplicationNumber=${params?.filter?.applicationNumber}&applicantID=${params?.filter?.applicantID}&_sort=${params?.sort?.field || ''}&_order=${params?.sort?.order || ''}&_start=${_start || ''}&_end=${_end || ''}&_search=${params?.filter?.q_Logs || ''}&_filter=${params?.filter?.subscribersStatus ? params?.filter?.subscribersStatus?.toString() : ''}`);
        const res = {
          data: json.data.map((item, index) => ({ ...item, id: index })),
          total: json.total,
        };
        return res;
      } catch (e) {
        throw new Error("something went wrong, try again!");
      }
    }
    return baseDataProvider.getList(resource, params);
  },
  getMany: async (resource, params) => {
    if (resource == "mlclassfile") {
      try {
        const { status, headers, body, json }: any = await httpClient(
          `${API_URL_DOC}/ml/mlclassdetails?ids=${params.ids.join("&ids=")}`,
          { method: "GET" }
        );
        const res = json;
        return { data: res, total: headers.get("X-Total-Count") };
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (
      resource === "getAllBlockSections" ||
      resource === "getAllColumnSections"
    ) {
      try {
        const query = {
          filter: JSON.stringify({ id: params.ids }),
        };
        const { json }: any = await httpClient(
          API_URL_DOC + `/ml/${resource}?${stringify(query)}`,
          {
            method: "GET",
          }
        );
        const r = json;
        const res = {
          data: [...r.records],
        };
        return res;
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource === "getAllFieldsByDocumentId") {
      try {
        const { json }: any = await httpClient(
          API_URL_DOC + `/ml/getAllFieldsByDocumentId/6`,
          {
            method: "GET",
          }
        );
        const r = json;
        const res = {
          data: [...r.records],
          total: r.records.length,
        };
        return res;
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    return baseDataProvider.getMany(resource, params);
  },
  update: async (resource, params) => {
    if (resource === "unarchive") {
      // const subUrl = params.data.subUrl;
      // delete params.data.subUrl;
      const raw = JSON.stringify({ ...params.data });
      try {
        const { json, headers, status, error }: any = await httpClient(
          API_URL + "/subscriber/unarchive/" + params.id,
          {
            method: "PUT",
            body: raw,
          }
        );
        const r = json;
        const res = {
          data: {
            id: params.data.trn_id,
            ...r.records,
          },
        };
        return res;
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource === "mlclassdetails") {
      try {
        const { json }: any = await httpClient(
          `${API_URL_DOC}/ml` + `/updatePaystubTemplate`,
          {
            method: "POST",
            body: JSON.stringify(params.data),
          }
        );
        const r = json;
        const res = {
          data: {
            ...r.records,
          },
        };
        return res;
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource === "applicant") {
      //
      const subUrl = params.data.subUrl;
      delete params.data.subUrl;
      const raw = JSON.stringify({ ...params.data });
      try {
        const { json, headers, status }: any = await httpClient(
          API_URL_DOC + `/applicant/docupload${subUrl}`,
          {
            method: "POST",
            body: raw,
          }
        );
        const r = json;
        const res = {
          data: {
            id: params.data.trn_id,
            ...r.records,
          },
        };
        return res;
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource === "other_sections") {
      const subUrl = params.data.subUrl;
      delete params.data.subUrl;
      const raw = JSON.stringify({ ...params.data });
      try {
        // debugger;
        const { json, headers, status }: any = await httpClient(
          API_URL_DOC + `${subUrl}`,
          {
            method: "POST",
            body: raw,
          }
        );
        const r = json;
        const res = {
          data: {
            id: params.data.trn_id,
            ...r.records,
          },
        };
        return res;
      } catch (e: any) {
        throw new Error(e.message);
      }
    }

    if (resource === "deletedDocument") {
      // const subUrl = params.data.subUrl;
      // delete params.data.subUrl;
      const raw = JSON.stringify({ ...params.data });
      try {
        const { json, headers, status, error }: any = await httpClient(
          API_URL_DOC + "/document/deletedDocument/" + params.id,
          {
            method: "PUT",
            body: raw,
          }
        );
        const r = json;
        const res = {
          data: {
            id: params.data.trn_id,
            ...r.records,
          },
        };
        return res;
      } catch (e: any) {
        throw new Error(e.message);
      }
    }

    if (resource == "archive") {
      try {
        const { json }: any = await httpClient(
          `${API_URL}/subscriber/archive/${params.id}`,
          {
            method: "PUT",
            body: JSON.stringify(params.data),
          }
        );
        const res = json;
        return {
          data: { id: res.data[0].ApplicationNumber, ...res.data[0] },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    }

    if (resource == "editapplicationdetail") {
      try {
        const { json }: any = await httpClient(
          `${API_URL}/common/editapplicationdetail/${params.id}`,
          {
            method: "PUT",
            body: JSON.stringify(params.data),
          }
        );
        const res = json;
        // need to check new response body res.data[0].ApplicationNumber
        return {
          data: { id: params?.id, ...res.data },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    }

    if (resource == "tranferapp") {
      try {
        const { json }: any = await httpClient(
          `${API_URL}/subscriber/tranferapp/${params.id}`,
          {
            method: "PUT",
            body: JSON.stringify(params.data),
          }
        );
        const res = json;
        return {
          data: { id: res.data[0].ApplicationNumber, ...res.data[0] },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource == "removeapplicant") {
      try {
        const { json }: any = await httpClient(
          `${API_URL}/subscriber/removeapplicant/${params.id}`,
          {
            method: "PUT",
            body: JSON.stringify(params.data),
          }
        );
        const res = json;
        return {
          data: { id: params.id, ...res.data[0] },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource == "cancelapp") {
      try {
        const { json }: any = await httpClient(
          `${API_URL}/subscriber/cancelapp/${params.id}`,
          {
            method: "PUT",
          }
        );
        const res = json;
        return {
          data: { id: res.data[0].ID, ...res.data[0] },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    if (resource == "readdapplicant") {
      try {
        const { json }: any = await httpClient(
          `${API_URL}/subscriber/readdapplicant/${params.id}`,
          {
            method: "PUT",
            body: JSON.stringify(params.data),
          }
        );
        const res = json;
        return {
          data: { id: res.data[0].ID, ...res.data[0] },
        };
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    return baseDataProvider.update(resource, params);
  },
  insertSequence: async (payload) => {
    try {
      const { json }: any = await httpClient(API_URL + `/insertSequence`, {
        method: "POST",
        body: JSON.stringify(payload),
      });
      const r = json;
      const res = {
        data: { ...r.records },
      };
      return res;
    } catch (e: any) {
      throw new Error(e.message);
    }
  },
  getSubscriberDetails: async (id) => {
    try {
      const { json, headers, status }: any = await httpClient(
        API_URL_DOC + `/users/logo/${id}`
      );
      const r = json;
      const res = {
        data: { ...r.records },
      };
      return res;
    } catch (e: any) {
      throw new Error(e.message);
    }
  },

  //   viewNotes: async (params,id) => {
  //     const { pagination, filter } = params;
  //     const end = pagination.perPage * (pagination.page + 1);
  //     const start = pagination.perPage * pagination.page;

  //   try {
  //     const { json, headers, status }: any = await httpClient(API_URL_DOC + `/common/viewNotes`)
  //     const r = json;
  //     const res = {
  //       data: { ...r.records },
  //     };
  //     return res;
  //   } catch (e) {
  //     throw new Error("something went wrong, try again!");
  //   }
  // },
  deleteSequence: async (resource, payload) => {
    const subUrl = payload.subUrl;
    delete payload.subUrl;
    const raw = JSON.stringify({ ...payload });
    if (resource === "income") {
      try {
        const { json, headers, status }: any = await httpClient(
          API_URL_DOC + `${subUrl}`,
          {
            method: "DELETE",
            body: raw,
          }
        );
        const r = json;
        const res = {
          data: { ...r.records },
        };
        return res;
      } catch (e: any) {
        throw new Error(e.message);
      }
    }
    try {
      const { json }: any = await httpClient(API_URL + subUrl, {
        method: "POST",
        body: JSON.stringify(payload),
      });
      const r = json;
      const res = {
        data: { ...r.records },
      };
      return res;
    } catch (e: any) {
      throw new Error(e.message);
    }
  },
  checkEmailExist: async (params) => {
    try {
      const { json }: any = await httpClient(
        `${API_URL}/common/checkemailexist?${params?.email
          ? `email=${params?.email}`
          : params?.username
            ? `username=${params?.username}`
            : null
        }`,
        { method: "GET" }
      );
      const res = await json;
      return {
        data: { ...res },
      };
    } catch (error) {
      throw new Error("something went wrong, try again!");
    }
    //   const {json}:any = await httpClient(API_URL + `/getAllImages/${params.id}`, {
    //     method: "DELETE",
    //
    //   });
    //   const r = json;
    //   const res = {
    //     data: { ...r.records },
    //   };
    //   return res;
    // } catch (e: any) {
    //   throw new Error(e.message);
    // }
  },
  generateXml: async (payload) => {
    try {
      const { json }: any = await httpClient(
        API_URL + `/generateXmlForDocuments`,
        {
          method: "POST",
          body: JSON.stringify(payload),
        }
      );
      const r = json;
      const res = {
        data: { ...r },
      };
      return res;
    } catch (e: any) {
      throw new Error(e.message);
    }
  },
  uploadFile: async (myFromData: uploadFile) => {
    try {
      const formdata = new FormData();
      myFromData.files.forEach((file, item) => {
        formdata.append("files", file);
      });
      formdata.append("class_id", myFromData.classId);
      const response: any = await httpClient(API_URL_DOC + "/ml/addpdf", {
        method: "POST",
        body: formdata,
      });
      return response.json;
    } catch (e: any) {
      throw new Error(e.message);
    }
  },
  uploadFilePredictClass: async (myFromData: uploadFilePredictClass) => {
    try {
      const formdata = new FormData();
      formdata.append("files", myFromData.files);
      formdata.append("documenttype_id", myFromData.docTypeId);

      const res: any = await httpClient(API_URL_DOC + "/ml/predictclass", {
        method: "POST",
        body: formdata,
      });
      return res.json;
    } catch (e: any) {
      throw new Error(e.message);
    }

    // .then((response) => response.json())
    // .catch(
    //   (error: any) =>
    //     new Promise(function (resolve, reject) {
    //       reject({
    //         message:
    //           error?.body?.message || "something went wrong, try again!",
    //       });
    //     })
    // );
  },
  uploadDocument: (myFromData) => {
    const formdata = new FormData();
    formdata.append("trn_id", myFromData.id);
    formdata.append("files", myFromData.files);
    formdata.append("document_id", myFromData.document_id);
    formdata.append("source_id", myFromData.source_id);
    formdata.append("allowOfferLetter", "null");

    return httpClient(API_URL_DOC + `/applicant/document`, {
      method: "POST",
      body: formdata,
    })
      .then((response: any) => response.json)
      .catch(
        (error: any) =>
          new Promise(function (resolve, reject) {
            reject({
              message:
                error?.body?.message || "something went wrong, try again!",
            });
          })
      );
  },
  deleteDocument: (id) => {
    return httpClient(API_URL_DOC + `/applicant/deletedocument/${id}`, {
      method: "DELETE",
    })
      .then((response) => ({ data: { id: id } }))
      .catch(
        (error: any) =>
          new Promise(function (resolve, reject) {
            reject({
              message:
                error?.body?.message || "something went wrong, try again!",
            });
          })
      );
  },
  deleteDocumentAllIds: (id) => {
    return httpClient(API_URL_DOC + `/applicant/deleteiddocument/${id}`, {
      method: "DELETE",
    })
      .then((response) => ({ data: { id: id } }))
      .catch(
        (error: any) =>
          new Promise(function (resolve, reject) {
            reject({
              message:
                error?.body?.message || "something went wrong, try again!",
            });
          })
      );
  },
  deleteDocumentNoDoc: (id) => {
    return httpClient(API_URL_DOC + `/applicant/deletepaystub/${id}/0`, {
      method: "DELETE",
    })
      .then((response) => ({ data: { id: id } }))
      .catch(
        (error: any) =>
          new Promise(function (resolve, reject) {
            reject({
              message:
                error?.body?.message || "something went wrong, try again!",
            });
          })
      );
  },
  // getMetaData: async ({ document_id }) => {
  //   const myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/json");
  //   const trn_id = sessionStorage.getItem('trn_id')
  //   try {
  //     const { json, headers, status }: any = await httpClient(API_URL_DOC + `/income/docupload/getMetadata/${trn_id}/${document_id}`);
  //     // const res = {
  //     //   data: { ...json },
  //     // };
  //     const res = {
  //       data: {
  //         id: trn_id,
  //         ...json,
  //       },
  //     };
  //     //
  //     return res;
  //   } catch (e: any) {
  //     throw new Error(e.message);
  //   }
  // },
  disableValidationMessage: async (payload) => {
    try {
      const raw = JSON.stringify({ ...payload });
      const { json, headers, status }: any = await httpClient(
        API_URL_DOC + `/document/updateValidationMessages`,
        {
          method: "POST",
          body: raw,
        }
      );
      const res = {
        data: { ...json },
      };
      return res;
    } catch (e: any) {
      throw new Error(e.message);
    }
  },
  getLoggedUser: async (payload) => {
    try {
      const raw = JSON.stringify({ ...payload });
      const { json, headers, status }: any = await httpClient(
        API_URL_DOC + "/auth/superuser",
        {
          method: "POST",
          body: raw,
        }
      );
      const res = {
        data: { ...json },
      };

      return res;
    } catch (e: any) {
      throw new Error(e.message);
    }
  },
  // generateOtp: async (payload) => {
  //   // debugger;
  //   const myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/json");
  //   const raw = JSON.stringify({ ...payload });
  //   try {
  //     const response = await fetch(API_URL_DOC + "/auth/GenerateOtp", {
  //       method: 'POST',
  //       body: raw,
  //     });
  //     return { data: { ...await response.json() } }
  //   } catch (e: any) {
  //     throw new Error(e.message);
  //   }
  // },
  verifyOtp: async (payload) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const raw = JSON.stringify(payload);
    try {
      const result = await fetch(API_URL_DOC + "/auth/VerifyOtp", {
        method: "POST",
        body: raw,
        headers: myHeaders,
      });
      if (!result.ok) {
        console.log(result);
        throw new Error(`Error! status: ${result.status}`);
      }
      const res = await result.json();
      return { data: { ...res } };
    } catch (e: any) {
      throw new Error(e.message);
    }
  },
  paystubProcessing: async (payload: any) => {
    const subUrl = payload.subUrl;
    const method = payload?.method || "DELETE";
    // delete payload?.method;
    delete payload.subUrl;
    const raw = JSON.stringify({ ...payload });
    try {
      const { json, headers, status }: any = await httpClient(
        API_URL_DOC + `${subUrl}`,
        {
          method: method,
          body: raw,
        }
      );
      return { data: { ...json } };
    } catch (e: any) {
      throw new Error(e.message);
    }
  },
  downloadZip: async (payload: any) => {
    try {
      const { data } = JSON.parse(<any>localStorage.getItem("auth")) || {};

      const headers = new Headers();
      headers.append("authorization", data?.tokendata || null);
      headers.append("trn_id", data?.trn_id || null);

      // Make the fetch request with the headers
      const response = await fetch(
        API_URL + "/common/getdownloadalldocs/" + payload,
        {
          method: "GET",
          headers: headers,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch ZIP file");
      }

      // Convert the response body to a Blob
      const blob = await response.blob();

      // Create a Blob URL for the ZIP file
      const url = window.URL.createObjectURL(blob);

      // Create a temporary link element and trigger the download
      const a = document.createElement("a");
      a.href = url;
      a.download = "docszip.zip"; // Set the desired file name
      a.click();

      // Clean up by revoking the URL object
      window.URL.revokeObjectURL(url);
      // const { body }: any = await httpClient(API_URL + '/common/getdownloadalldocs/' + payload);
      return { data: response };
    } catch (error: any) {
      throw new Error(error.message);
    }
  },
  validateInvitation: async (payload: any) => {
    try {
      const headers = new Headers();
      headers.append("auth", payload?.auth || null);

      // Make the fetch request with the headers
      const response = await httpClient(
        API_URL + "/common/validate-invitation",
        {
          method: "GET",
          headers: headers,
        }
      );
      return { data: response };
    } catch (error: any) {
      throw new Error(error.message);
    }
  },
};
